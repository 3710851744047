export const gameFactoryABI = [
  {
    inputs: [{internalType: 'address', name: '_master', type: 'address'}],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'contractAddress',
        type: 'address',
      },
      {indexed: false, internalType: 'uint256', name: 'id', type: 'uint256'},
      {
        indexed: false,
        internalType: 'uint256',
        name: 'initialDeposit',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'creator',
        type: 'address',
      },
      {indexed: false, internalType: 'uint256', name: 'fee', type: 'uint256'},
      {indexed: false, internalType: 'string', name: 'name', type: 'string'},
      {indexed: false, internalType: 'uint256', name: 'roi', type: 'uint256'},
      {
        indexed: false,
        internalType: 'uint256',
        name: 'maxDeposit',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'minDeposit',
        type: 'uint256',
      },
      {indexed: false, internalType: 'string', name: 'file', type: 'string'},
    ],
    name: 'CreateGame',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [
      {internalType: 'uint256', name: 'fee', type: 'uint256'},
      {internalType: 'uint256', name: 'maxDeposit', type: 'uint256'},
      {internalType: 'uint256', name: 'minDeposit', type: 'uint256'},
      {internalType: 'uint256', name: 'roi', type: 'uint256'},
      {internalType: 'string', name: 'file', type: 'string'},
      {internalType: 'string', name: 'name', type: 'string'},
    ],
    name: 'createGame',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'gamesCount',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: '', type: 'address'}],
    name: 'isGame',
    outputs: [{internalType: 'bool', name: '', type: 'bool'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'master',
    outputs: [{internalType: 'address', name: '', type: 'address'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{internalType: 'address', name: '', type: 'address'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: '_master', type: 'address'}],
    name: 'setMaster',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: 'newOwner', type: 'address'}],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {stateMutability: 'payable', type: 'receive'},
];
