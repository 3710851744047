import {Instruction} from './Instruction';
import {steps} from './steps';
import './styles.css';

export function LandingInstructions() {
  return (
    <div className="instructions" id="how-it-works">
      {steps.map((step, index) => (
        <Instruction
          key={index}
          currentStep={index + 1}
          steps={steps.length}
          heading={step.heading}
          content={step.description}
          imageSrc={step.imageSrc}
        />
      ))}
    </div>
  );
}
