import {Link} from 'react-router-dom';
import {Icons} from '../Icons';
import {NewGameDialog} from '../NewGameDialog';

export function DashboardMobileNav() {
  return (
    <div className="fixed bottom-[-1px] left-0 right-0 flex justify-between px-5 py-3 rounded-t-2xl bg-black md:hidden">
      <Link
        to="/dashboard"
        className="dashboard-aside-nav-item-icon"
        aria-label="All Games"
      >
        <Icons.AllGames />
      </Link>
      <Link
        to="/dashboard/my"
        className="dashboard-aside-nav-item-icon"
        aria-label="My Games"
      >
        <Icons.MyGames />
      </Link>
      <NewGameDialog>
        <button className="min-w-[2.875rem] min-h-[2.875rem] flex justify-center items-center rounded-full bg-white text-black outline-none">
          <Icons.Plus width={20} height={20} color="#000" />
        </button>
      </NewGameDialog>
      <Link
        to="/dashboard/hosted"
        className="dashboard-aside-nav-item-icon"
        aria-label="Hosted Games"
      >
        <Icons.HostedGames />
      </Link>
      <Link
        to="/faq"
        className="dashboard-aside-nav-item-icon"
        aria-label="FAQ"
      >
        <Icons.FAQ />
      </Link>
    </div>
  );
}
