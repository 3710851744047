import {useTimeDifference} from 'hooks/useTimeDifference';
import {WithdrawDialog} from 'screens/Dashboard/components/WithdrawDialog';
import {Game, GameStatus, UserGameStatus} from 'types/Game';
import {GameDepositDialog} from 'ui-kit/GameDepositDialog';
import './styles.css';
import {useEffect} from 'react';

interface GameButtonProps {
  status: GameStatus;
  userGameStatus?: UserGameStatus;
  game: Game;
  refetchGame: () => void;
}

export function GameButton({
  status,
  userGameStatus,
  game,
  refetchGame,
}: GameButtonProps) {
  const endsInMs = new Date(game.finishAt).getTime();
  const isEnded =
    endsInMs < Date.now() ||
    status === GameStatus.FINISHED ||
    userGameStatus === UserGameStatus.FAILED;

  if (
    status === GameStatus.IN_PROGRESS &&
    (endsInMs === null || endsInMs === undefined)
  ) {
    throw new Error('endsInS is required when status is IN_PROGRESS');
  }
  const {days, hours, minutes, seconds} = useTimeDifference(endsInMs, true);

  useEffect(() => {
    if (
      !isEnded &&
      days === 0 &&
      hours === 0 &&
      +minutes === 0 &&
      +seconds === 0
    ) {
      refetchGame?.();
    }
  }, [isEnded, days, hours, minutes, seconds]);

  if (userGameStatus === UserGameStatus.WITHDRAW_AVAILABLE && isEnded) {
    return <WithdrawDialog game={game} onSuccessfulWithdraw={refetchGame} />;
  }

  if (userGameStatus === UserGameStatus.WITHDRAW_AVAILABLE && !isEnded) {
    return (
      <div className="flex flex-col justify-between w-full xl:w-auto min-[1380px]:h-[128px] max-h-full">
        <WithdrawDialog game={game} onSuccessfulWithdraw={refetchGame} />
        <span className="mt-auto pt-[1.125rem] text-sm leading-4 text-center text-[#A0A4AC] md:text-base">
          Ends in{' '}
          <span className="text-black">
            {hours}:{minutes}:{seconds}
          </span>
        </span>
      </div>
    );
  }

  if (isEnded) return null;

  return (
    <div className="flex flex-col justify-between w-full xl:w-auto min-[1380px]:h-[128px] max-h-full">
      <GameDepositDialog game={game} onSuccessfulDeposit={refetchGame}>
        <button className="btn-game bg-black">
          {userGameStatus === UserGameStatus.IN_PROGRESS
            ? 'Make another bet'
            : 'Join the game'}
        </button>
      </GameDepositDialog>
      <span className="mt-auto pt-[1.125rem] text-sm leading-4 text-center text-[#A0A4AC] md:text-base">
        Ends in{' '}
        <span className="text-black">
          {hours}:{minutes}:{seconds}
        </span>
      </span>
    </div>
  );
}
