import {useWeb3React} from '@web3-react/core';
import {WalletConnect} from '@web3-react/walletconnect-v2';
import {TARGET_CHAIN} from 'lib/web3-react/constants/chains';
import {useCallback} from 'react';

export const useChain = () => {
  const {chainId, isActive, connector} = useWeb3React();

  const switchChain = useCallback(
    async (desiredChainId: number) => {
      if (!isActive || desiredChainId === chainId) {
        return;
      }

      if (connector instanceof WalletConnect) {
        await connector.activate(desiredChainId);
      } else {
        try {
          await connector.activate(desiredChainId);
        } catch (error) {
          await connector.activate(TARGET_CHAIN);
        }
      }
    },
    [connector, chainId, isActive]
  );

  return {
    chainId,
    switchChain,
  };
};
