import {
  SyntheticEvent,
  forwardRef,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import {ControllerRenderProps} from 'react-hook-form';
import {Icons} from 'ui-kit/Icons';
import {readImage} from 'utils';

const noImage = require('assets/no-image.png') as string;

interface FormImageProps extends Omit<ControllerRenderProps, 'ref'> {
  label?: string;
}

export const FormImage = forwardRef<HTMLLabelElement, FormImageProps>(
  ({value, onChange, label}, ref) => {
    const id = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const [preview, setPreviewSrc] = useState<string>(noImage);

    useEffect(() => {
      const fileList = value as FileList;

      if (!fileList || fileList.length <= 0) return;

      readImage(fileList[0])
        .then(src => {
          setPreviewSrc(src as string);
        })
        .catch(() => {
          setPreviewSrc(noImage);
        });
    }, [value]);

    const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
      const fileList = e.currentTarget.files;
      if (!fileList || fileList.length === 0) {
        setPreviewSrc(noImage);
        onChange(null);
        return;
      }

      readImage(fileList[0])
        .then(src => {
          setPreviewSrc(src as string);
        })
        .catch(() => {
          setPreviewSrc(noImage);
        });
      onChange(fileList);
    };

    const handleReset = () => {
      if (inputRef.current) inputRef.current!.value = '';
      setPreviewSrc(noImage);
      onChange(null);
    };

    return (
      <div className="grid gap-2">
        {label && (
          <label htmlFor={id} className="text-sm">
            {label}
          </label>
        )}
        <div className="flex items-center max-[340px]:gap-4 gap-8">
          <img
            src={preview}
            alt=""
            className="w-[80px] h-[84px] sm:w-[108px] sm:h-[113px] rounded-2xl object-cover bg-[#F9F9F9]"
          />
          <div className="flex flex-col items-center gap-2">
            <span className="whitespace-nowrap text-xs text-center text-[#7f7f7f]">
              max 1mb, .jpeg, .png, .heic
            </span>
            <label
              htmlFor={id}
              className="flex justify-center items-center gap-2 px-3 py-2 border border-[#DFDFDF] rounded-lg bg-[#F7F7F7] text-xs cursor-pointer select-none"
              onClick={handleReset}
              ref={ref}
            >
              <input
                type="file"
                id={id}
                className="hidden"
                accept="image/jpeg, image/png, image/heic"
                onChange={handleChange}
                ref={inputRef}
              />
              <Icons.Image />
              upload image
            </label>
          </div>
        </div>
      </div>
    );
  }
);
