import {CoinbaseWallet} from '@web3-react/coinbase-wallet';
import {MetaMask} from '@web3-react/metamask';
import {Connector} from '@web3-react/types';
import {WalletConnect} from '@web3-react/walletconnect-v2';
import {Provider} from './types';

export function getProviderByConnector(connector: Connector): Provider {
  if (connector instanceof MetaMask) return 'MetaMask';
  if (connector instanceof WalletConnect) return 'WalletConnect';
  if (connector instanceof CoinbaseWallet) return 'Coinbase';
  throw new Error('Unsupported connector');
}
