import {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Logo} from 'ui-kit/Logo';
import {Icons} from '../Icons';
import './styles.css';

function FooterIconLink({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="footer-icon-link"
    >
      {children}
    </a>
  );
}

export function LandingFooter() {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className="w-full px-2.5 pb-2.5 sm:p-5">
      <div className="footer-content">
        <div className="flex items-center w-full">
          <Logo />
          <div className="flex items-center gap-2 ml-auto">
            <FooterIconLink href="https://discord.gg/9zMaKTaf7Q">
              <Icons.Discord />
            </FooterIconLink>
            <FooterIconLink href="https://x.com/ponzidev">
              <Icons.X />
            </FooterIconLink>
          </div>
        </div>
      </div>
      <div className="footer-info">
        <Link to="/terms" target="_blank">
          Terms of Service
        </Link>
        <p>@{year} Ponzi</p>
      </div>
    </footer>
  );
}
