import {DialogClose} from '@radix-ui/react-dialog';
import {Controller, useFormContext} from 'react-hook-form';
import {Game} from 'types/Game';
import {FieldEthereumIcon} from 'ui-kit/FieldEthereumIcon';
import {FormCheckbox} from 'ui-kit/FormCheckbox';
import {FormField} from 'ui-kit/FormField';
import {FormMessage} from 'ui-kit/FormMessage';
import {GameDepositType} from './scheme';
import {Link} from 'react-router-dom';

interface GameDepositFormProps {
  game: Game;
  isLoading: boolean;
  error: string | null;
}

export function GameDepositForm({
  game,
  isLoading,
  error,
}: GameDepositFormProps) {
  const formCtx = useFormContext<GameDepositType>();

  const isInvalid = !formCtx.formState.isValid;
  const isNotAccepted = !formCtx.watch('isRulesAccepted');

  return (
    <div className="flex flex-col gap-8 px-5 py-8 md:px-[3.75rem] md:pt-[3.75rem] md:pb-[3.125rem] md:gap-[3.125rem]">
      <h2 className="text-center text-xl leading-6 text-[#101520] md:text-[1.75rem] md:leading-8">
        Add your deposit to join the
        <br />
        <span className="max-w-[488px] block truncate">{game.name}</span>
      </h2>
      <div className="flex flex-col gap-4 w-full max-w-[488px]">
        <div className="grid gap-2">
          <FormField
            type="number"
            label="Your deposit"
            placeholder={`${game.minDeposit} ETH`}
            leftIcon={<FieldEthereumIcon />}
            rightIcon={
              <span className="text-xs text-[#A8A8A8]">
                Max.&nbsp;{game.maxDeposit}&nbsp;ETH
              </span>
            }
            min={game.minDeposit}
            max={game.maxDeposit}
            step={0.001}
            {...formCtx.register('deposit', {
              valueAsNumber: true,
            })}
            disabled={isLoading}
          />
          <FormMessage name="deposit" />
        </div>
        <div className="flex items-center gap-2.5 p-5 rounded-2xl border border-[#DDF2DB] bg-[#EBFDE9] text-[#536751] text-sm">
          To join the game, make a deposit. You will get shares of new deposits until you fulfill the specified ROI. If nobody joins after you - you will win the current pool.
        </div>
        <Controller
          name="isRulesAccepted"
          control={formCtx.control}
          render={({field}) => (
            <FormCheckbox
              label="I confirm that I have read the Terms of Service and agree to them"
              className="text-[#8C8C8C]"
              {...field}
            />
          )}
        />
        {error && (
          <div className="flex items-center text-red-600 text-xs sm:text-sm">
            {error}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <button
          type="submit"
          className="btn w-full sm:w-[279px]"
          disabled={isInvalid || isLoading || isNotAccepted}
        >
          {isLoading ? 'Loading...' : 'Make deposit and join game'}
        </button>
        <Link to="/terms" target="_blank" className="text-sm">
          Terms of Service
        </Link>
      </div>
    </div>
  );
}
