import {Bet, BetStatus} from 'types/Game';

/**
 * Calculate timestamp of end of the game
 * @description Game ends 10 minutes after the last bet
 * @param bets array of bets
 * @returns timestamp of end of the game
 */
export const calcEndOfGame = (bets: Bet[]) =>
  new Date(bets[bets.length - 1].createdAt).getTime() + 1000 * 60 * 10;

/**
 * Calculate profit for host of the game
 * @param hardPool
 * @param softPool
 * @param hostFee
 * @param platformFee
 */
export const calcProfit = (
  hardPool: number,
  softPool: number,
  hostFee: number,
  platformFee: number
) => (((hardPool + softPool) / 100) * (hostFee + platformFee)) / 2;

/**
 * Calculate profit for platform of the game
 *
 * Filter is used before sorting to reduce the number of iterations
 *
 * @param bets array of bets
 * @param account address of the user
 */
export const getBetForWithdraw = (
  bets: Bet[],
  account: string
): Bet | undefined => {
  const myBetsForWithdraw = bets.filter(
    bet =>
      (bet.status === BetStatus.WITHDRAW_AVAILABLE ||
        bet.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE) &&
      bet.user.wallet === account
  );
  return getSortedBets(myBetsForWithdraw)?.[0];
};

export const getSortedBets = (bets: Bet[]) => {
  return bets.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
};
