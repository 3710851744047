import {DialogClose} from '@radix-ui/react-dialog';
import {useWeb3React} from '@web3-react/core';
import {useConnectedWalletIcon} from 'hooks/useConnectedWalletIcon';
import {useEffect, useMemo, useState} from 'react';
import {Price} from 'services/price';
import {FormField} from 'ui-kit/FormField';
import {Icons} from 'ui-kit/Icons';
import {withPrecision} from 'utils';
import {formatAddress} from 'utils/web3';

/**
 * Fallback value for ETH to USD conversion
 */
const ETH_TO_USD = 2365.56;

interface WithdrawFormProps {
  amount: number;
  isWithdrawing: boolean;
  isLoading: boolean;
  error: string | null;
}

export function WithdrawForm({
  amount,
  isWithdrawing,
  isLoading,
  error,
}: WithdrawFormProps) {
  const {account} = useWeb3React();
  const walletIcon = useConnectedWalletIcon();
  const [depositUSD, setDepositInUSD] = useState(amount * ETH_TO_USD);

  const shortAddress = useMemo(
    () => account && formatAddress(account, 6, 4),
    [account]
  );

  useEffect(() => {
    if (isNaN(amount) || amount <= 0) return;

    const ethPrice = Price.get('eth').usd;
    setDepositInUSD(amount * ethPrice);
  }, [amount]);

  return (
    <div className="flex flex-col gap-8 px-5 pt-[3.75rem] pb-8 md:py-[3.75rem] md:px-[6.25rem]">
      <h2 className="text-center text-xl leading-6 text-[#101520] md:text-[1.75rem] md:leading-9">
        You're about to <br />
        withdraw your winnings
      </h2>
      <p className="max-w-[267px] md:max-w-[369px] text-center text-sm leading-5 text-[#8C8C8C]">
        Withdrawing may take some time to propagate.
      </p>
      <div className="flex flex-col gap-4 md:gap-6">
        <div>
          <FormField
            label="Amount to withdraw"
            type="text"
            leftIcon={
              <div className="flex items-center justify-center min-w-[1.5rem] h-6 rounded-full bg-white">
                <Icons.Ethereum />
              </div>
            }
            rightIcon={
              !isLoading && (
                <span className="flex items-center whitespace-nowrap font-medium text-xs leading-normal text-[#101520] opacity-40">
                  ~ {depositUSD.toFixed(2)} USD
                </span>
              )
            }
            readOnly
            disabled
            value={isLoading ? 'Loading...' : withPrecision(amount)}
          />
        </div>
        <FormField
          label="Your address"
          readOnly
          value={shortAddress}
          leftIcon={
            <div className="flex items-center justify-center min-w-[1.5rem] h-6 rounded-full bg-white">
              <img
                src={walletIcon}
                alt="Wallet icon"
                className="w-[19px] h-[19px]"
              />
            </div>
          }
          disabled
        />
        {error && (
          <div className="flex items-center max-w-[369px] text-red-600 text-xs sm:text-sm">
            {error}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-6 items-center">
        <button
          className="btn w-full max-w-[266px]"
          type="submit"
          disabled={isWithdrawing}
        >
          {isWithdrawing ? 'Loading...' : 'Withdraw funds'}
        </button>
        <DialogClose
          className="text-sm leading-6 disabled:opacity-50"
          disabled={isWithdrawing}
        >
          Cancel
        </DialogClose>
      </div>
    </div>
  );
}
