interface ProgressBarVariant {
  container: string;
  content: string;
  bar: string;
  barProgress: string;
}

export const variants: {[key: string]: ProgressBarVariant} = {
  DEFAULT: {
    container: 'flex flex-col gap-2 items-start w-40',
    content: 'flex justify-between w-full text-xs leading-normal',
    bar: 'relative w-full h-1.5 rounded-full bg-[#F3F3F3]',
    barProgress: 'h-1.5 max-w-full rounded-full bg-black',
  },
  small: {
    container: 'flex flex-col gap-2 items-start w-[93px]',
    content: 'flex justify-end w-full text-xs leading-normal',
    bar: 'relative w-full h-1 rounded-full bg-[#F3F3F3]',
    barProgress: 'h-1 max-w-full rounded-full bg-black',
  },
};
