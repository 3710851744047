import {ToastProps} from 'ui-kit/Toast';
import {create} from 'zustand';

interface DashboardState {
  isWalletAuthenticated: boolean;
  toasts: ToastProps[];
  addToast: (newToast: Omit<ToastProps, 'id'>) => void;
  clearToasts: () => void;
  removeToast: (id: string) => void;
  setIsWalletAuthenticated: (isWalletAuthenticated: boolean) => void;
}

export const useDashboardStore = create<DashboardState>()(set => ({
  isWalletAuthenticated: false,
  toasts: [],
  addToast: newToast =>
    set(state => ({
      toasts: [
        ...state.toasts,
        {
          id: crypto.randomUUID(),
          ...newToast,
        },
      ],
    })),
  clearToasts: () => set({toasts: []}),
  removeToast: id =>
    set(state => ({
      toasts: state.toasts.filter(toast => toast.id !== id),
    })),
  setIsWalletAuthenticated: isWalletAuthenticated =>
    set({isWalletAuthenticated}),
}));
