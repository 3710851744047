import type {AddEthereumChainParameter} from '@web3-react/types';
import {STAGE} from '../../../constants';

export const MAINNET_CHAIN: AddEthereumChainParameter = {
  chainId: 42161,
  chainName: 'Arbitrum One',
  rpcUrls: [
    'https://arbitrum.llamarpc.com',
    'https://rpc.ankr.com/arbitrum',
    'https://arbitrum.drpc.org',
  ],
  nativeCurrency: {
    name: 'Arbitrum',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const TESTNET_CHAIN: AddEthereumChainParameter = {
  chainId: 11155111,
  chainName: 'Sepolia',
  rpcUrls: ['https://ethereum-sepolia.publicnode.com'],
  nativeCurrency: {
    name: 'SepoliaETH',
    symbol: 'SepoliaETH',
    decimals: 18,
  },
};

export const TARGET_CHAIN =
  STAGE === 'development' ? TESTNET_CHAIN : MAINNET_CHAIN;
