import {Link} from 'react-router-dom';
import {Icons} from './Icons';

export function Logo() {
  return (
    <Link
      to="/"
      className="flex items-center gap-1.5 font-semibold text-lg leading-none text-white"
    >
      <Icons.Logo />
      ponzi.market
    </Link>
  );
}
