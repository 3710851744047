import {CoinbaseWallet} from '@web3-react/coinbase-wallet';
import {initializeConnector} from '@web3-react/core';

import {Connection} from './connections';
import {TARGET_CHAIN} from './constants/chains';

export function buildCoinbaseWalletConnector() {
  const [coinbaseWallet, hooks] = initializeConnector<CoinbaseWallet>(
    actions =>
      new CoinbaseWallet({
        actions,
        options: {
          url: TARGET_CHAIN.rpcUrls[0],
          appName: 'ponzi.market',
        },
      })
  );
  const injectedConnection: Connection = {
    connector: coinbaseWallet,
    hooks: hooks,
    type: 'Coinbase',
  };

  return injectedConnection;
}
