import {DialogClose, DialogTitle} from '@radix-ui/react-dialog';
import {Link} from 'react-router-dom';

const sandClock = require('assets/sand-clock.png') as string;

export function WithdrawSuccess() {
  return (
    <div className="flex flex-col gap-8 px-5 pt-10 pb-5 md:px-[6.25rem] md:py-[3.75rem]">
      <div className="flex flex-col items-center gap-6">
        <img src={sandClock} className="w-[3.75rem] h-[4.625rem]" alt="" />
        <DialogTitle asChild>
          <h2 className="text-center text-xl leading-6 text-[#101520] md:text-[1.75rem] md:leading-9">
            You’ve successfully
            <br />
            withdrawn your funds
          </h2>
        </DialogTitle>
        <p className="max-w-[280px] md:max-w-[369px] text-center text-sm leading-5 text-[#8C8C8C]">
          Congratulations! Your winnings have been securely transferred back to
          your wallet.
        </p>
      </div>
      <div className="flex flex-col gap-6 items-center">
        <DialogClose asChild>
          <Link to="/dashboard" className="btn w-full max-w-[266px]">
            Back to all games
          </Link>
        </DialogClose>
      </div>
    </div>
  );
}
