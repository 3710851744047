import {useWeb3React} from '@web3-react/core';
import {formatEther} from 'ethers/lib/utils';
import {useMemo} from 'react';
import {useHostedGamesStore} from 'screens/HostedGames/store';
import {gameService} from 'services/games';
import {Game} from 'types/Game';
import {EthereumValue} from 'ui-kit/EthereumValue';
import {GameButton} from 'ui-kit/GameButton';
import {GameCardLg} from 'ui-kit/GameCardLg';
import {Icons} from 'ui-kit/Icons';
import {Tooltip} from 'ui-kit/Tooltip';
import {withPrecision} from 'utils';
import {calcProfit} from 'utils/game';

export function HostedGameCard({game}: {game: Game}) {
  const {
    name,
    thumbnailUrl,
    hardPool,
    softPool,
    roi,
    minDeposit,
    maxDeposit,
    status,
    userGameStatus,
    createdAt,
    hostFee,
    platformFee,
  } = game;
  const date = new Date(createdAt).toLocaleDateString('en-UK', {
    day: 'numeric',
    month: 'short',
  });

  const time = new Date(createdAt).toLocaleTimeString('en-UK', {
    hour: 'numeric',
    minute: 'numeric',
  });

  const totalProfit = useMemo(
    () =>
      calcProfit(
        +formatEther(hardPool),
        +formatEther(softPool),
        hostFee,
        platformFee
      ),
    [hardPool, softPool, hostFee, platformFee]
  );

  const {isActive} = useWeb3React();
  const setGame = useHostedGamesStore(s => s.setGame);

  const handleGameRefetch = async () => {
    if (!isActive) return;
    const res = await gameService.getGameById(game.id);
    if (!res) return;

    setGame(res);
  };

  return (
    <GameCardLg
      game={game}
      imgSrc={thumbnailUrl}
      gridValues={[
        {
          label: 'Current pool',
          value: <EthereumValue value={+formatEther(softPool)} />,
        },
        {
          label: 'ROI',
          value: `${roi}%`,
        },
        {
          label: 'Fee',
          value: `${hostFee + platformFee}%`,
        },
        {
          label: 'Min. limit',
          value: <EthereumValue value={minDeposit} />,
        },
        {
          label: 'Max. limit',
          value: <EthereumValue value={maxDeposit} />,
        },
      ]}
      action={
        <div className="flex flex-col items-center w-full gap-4 pb-2 md:pb-0">
          <GameButton
            status={status}
            userGameStatus={userGameStatus}
            game={game}
            refetchGame={() => handleGameRefetch().catch}
          />
        </div>
      }
    >
      <span className="block mb-2 game-card-lg-createdAt lg:hidden">
        <span className="game-card-lg-muted">Created</span> {date} at {time}
      </span>
      <div className="flex flex-col justify-between items-start gap-2 h-full">
        <div className="flex flex-col items-start gap-2">
          <h5 className="w-[150px] text-base line-clamp-2 lg:w-full min-[1380px]:w-[300px] md:text-lg lg:line-clamp-1">
            {name}
          </h5>
          <span className="hidden game-card-lg-createdAt lg:block">
            <span className="text-xs text-[#A0A4AC]">Created</span> {date} at{' '}
            {time}
          </span>
        </div>
        <div className="flex flex-col items-start gap-2">
          <div className="flex items-center gap-2">
            <span className="text-[0.625rem] md:text-xs text-[#A0A4AC]">
              Total profit
            </span>
            <Tooltip
              contentProps={{
                align: 'start',
              }}
              content={
                <>
                  <span className="text-black">Fees explanation</span>
                  Game host sets up a commission ranging from 2% to 10% on each
                  deposit to this game. Half of this commission goes to the game
                  host, and the other half is allocated to platform development.
                </>
              }
            >
              <Icons.Info width={12} height={12} />
            </Tooltip>
          </div>
          <EthereumValue value={withPrecision(totalProfit)} />
        </div>
      </div>
    </GameCardLg>
  );
}
