import axios, {AxiosInstance, InternalAxiosRequestConfig} from 'axios';
import {API_BASE_URL} from '../../../constants';
import {IAnalytic, analyticStore} from '../store/analytic';

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  return config;
};

export class AnalyticService {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_BASE_URL,
      headers: {'Content-Type': 'application/json'},
      withCredentials: true,
    });

    this._api.defaults.headers.put['Content-Type'] = 'application/json';
    this._api.interceptors.request.use((config: InternalAxiosRequestConfig) =>
      requestInterceptor(config)
    );
  }

  async getAnalytics() {
    analyticStore.setState({isAnalyticLoading: true});

    try {
      const url = '/api/analytics/total-fees';

      const {data} = await this._api.get<IAnalytic>(url);

      analyticStore.setState({
        totalFees: data.totalFees,
        averageFeePerUser: data.averageFeePerUser,
      });
    } catch (error) {
      console.warn(error);
    } finally {
      analyticStore.setState({isAnalyticLoading: false});
    }
    return [];
  }
}

export const analyticService = new AnalyticService();
