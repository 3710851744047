import {useWeb3React} from '@web3-react/core';
import {formatEther} from 'ethers/lib/utils';
import {useEffect, useState} from 'react';
import {useMyGamesStore} from 'screens/MyGames/store';
import {gameService} from 'services/games';
import {BetStatus, Game} from 'types/Game';
import {EthereumValue} from 'ui-kit/EthereumValue';
import {GameButton} from 'ui-kit/GameButton';
import {GameCardLg} from 'ui-kit/GameCardLg';
import {ProgressBar} from 'ui-kit/ProgressBar';
import {getSortedBets} from 'utils/game';
import {StatusBadge} from '../StatusBadge';

export function MyGameCard({game}: {game: Game}) {
  const {
    name,
    thumbnailUrl,
    roi,
    membersCount,
    status,
    userGameStatus,
    bets,
    hostFee,
    platformFee,
  } = game;
  const {account, isActive} = useWeb3React();
  const setGame = useMyGamesStore(s => s.setGame);
  const [number, setNumber] = useState(0);

  const myBets = bets.filter((bet, i) => {
    return bet.user.wallet === account;
  });

  if (myBets.length <= 0) return null;
  const firstBet = getSortedBets(myBets).find((bet, i) => {
    return bet.status !== BetStatus.WITHDRAWED || i === myBets.length - 1;
  });

  useEffect(() => {
    if (!firstBet) return;

    const myBetIndex = getSortedBets(bets).findIndex(
      bet => bet.id === firstBet.id
    );

    setNumber(myBetIndex + 1);
  }, []);

  if (!firstBet) return null;

  const deposit = Number(formatEther(firstBet.amount)) || 0;
  const progress = Math.round(
    (Number(+formatEther(firstBet.collectedAmount)) /
      Number(formatEther(firstBet.amount))) *
      100
  );

  const handleGameRefetch = async () => {
    if (!isActive) return;
    const res = await gameService.getGameById(game.id);
    if (!res) return;

    setGame(res);
  };

  return (
    <GameCardLg
      game={game}
      imgSrc={thumbnailUrl}
      gridValues={[
        {
          label: 'Your bet',
          value: <EthereumValue value={deposit} />,
        },
        {
          label: 'Fee',
          value: `${hostFee + platformFee}%`,
        },
        {
          label: 'Your number',
          value: number,
        },
        {
          label: 'ROI',
          value: `${roi}%`,
        },
        {
          label: 'Total deposits', // Changed from total members to total deposits
          value: bets.length, // Changed from membersCount to bets.length
        },
      ]}
      action={
        <GameButton
          status={status}
          userGameStatus={userGameStatus}
          game={game}
          refetchGame={() => handleGameRefetch().catch}
        />
      }
    >
      <div className="flex flex-col justify-between items-start gap-2 h-full">
        <StatusBadge status={status} userGameStatus={userGameStatus} />
        <h5 className="w-[150px] text-base line-clamp-2 lg:w-full min-[1380px]:w-[300px] md:text-lg lg:line-clamp-1">
          {name}
        </h5>
        {firstBet.status !== BetStatus.WITHDRAWED ? (
          <ProgressBar current={progress} total={roi + 100} />
        ) : (
          <div className="min-[1380px]:h-8" />
        )}
      </div>
    </GameCardLg>
  );
}
