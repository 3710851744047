import {SVGAttributes} from 'react';

interface Variants {
  [key: string]: {
    container: string;
    checkbox: string;
    checked: string;
    unchecked: string;
    icon: SVGAttributes<SVGElement>;
  };
}

export const variants: Variants = {
  DEFAULT: {
    container:
      'flex items-center gap-4 font-medium text-left text-sm cursor-text',
    checkbox:
      'flex justify-center items-center min-w-[1.25rem] h-5 rounded cursor-pointer transition-colors sm:min-w-[1.5rem] sm:h-6',
    checked: 'bg-black',
    unchecked: 'bg-[#F3F3F3]',
    icon: {
      width: 16,
      height: 16,
    },
  },
  small: {
    container:
      'flex items-center gap-3 font-medium text-sm leading-4 cursor-pointer',
    checkbox:
      'flex justify-center items-center w-4 h-4 rounded cursor-pointer transition-colors',
    checked: 'bg-black',
    unchecked: 'bg-[#F4F4F4]',
    icon: {
      width: 12,
      height: 12,
    },
  },
};
