import {zodResolver} from '@hookform/resolvers/zod';
import {DialogTrigger} from '@radix-ui/react-dialog';
import {AnimatePresence} from 'framer-motion';
import {ReactNode, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {NewGameMain} from './NewGameMain';
import {NewGameStep1} from './NewGameStep1';
import {NewGameStep2} from './NewGameStep2';
import {NewGameStep3} from './NewGameStep3';
import {NewGameStep4} from './NewGameStep4';
import {NewGameSuccess} from './NewGameSuccess';
import {NewGameFormSchemeFull, NewGameFormType} from './scheme';
import {useWeb3React} from '@web3-react/core';
import {ConnectWalletDialog} from '../ConnectWalletDialog';
import {useLocation} from 'react-router-dom';
import {allGamesStore} from 'screens/AllGames';
import {gameService} from 'services/games';
import {myGamesStore} from 'screens/MyGames/store';
import {hostedGamesStore} from 'screens/HostedGames/store';

interface NewGameDialogProps {
  children: ReactNode;
}

export function NewGameDialog({children}: NewGameDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const {isActive} = useWeb3React();
  const {pathname} = useLocation();

  const form = useForm<NewGameFormType>({
    defaultValues: {
      step: 0,
      name: '',
      roi: 50,
      minDeposit: NaN,
      maxDeposit: NaN,
      fee: 5,
      isRulesAccepted: false,
      firstDeposit: NaN,
      thumbnail: null,
    },
    resolver: zodResolver(NewGameFormSchemeFull),
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const step = form.watch('step');
  const isLoading = form.watch('isLoading');

  const handleDialogClose = (isOpen: boolean) => {
    setIsOpen(isOpen);
    if (isOpen) return;

    form.reset();
    form.setValue('thumbnail', null);

    if (step === 5) {
      if (pathname.endsWith('/dashboard')) {
        allGamesStore.setState({page: 0});
        gameService.getAllGames().catch(console.warn);
      } else if (pathname.endsWith('/hosted')) {
        myGamesStore.setState({page: 0});
        gameService.getHostedGames().catch(console.warn);
      } else if (pathname.endsWith('/my')) {
        hostedGamesStore.setState({page: 0});
        gameService.getMyGames().catch(console.warn);
      }
    }
  };

  if (!isActive)
    return (
      <ConnectWalletDialog onWalletConnected={() => handleDialogClose(true)}>
        {children}
      </ConnectWalletDialog>
    );

  return (
    <DialogRoot open={isOpen} onOpenChange={handleDialogClose}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal
            forceMount
            contentProps={{
              onInteractOutside: e => {
                if (isLoading) e.preventDefault();
              },
            }}
          >
            <FormProvider {...form}>
              {step === 0 && <NewGameMain />}
              {step === 1 && <NewGameStep1 />}
              {step === 2 && <NewGameStep2 />}
              {step === 3 && <NewGameStep3 />}
              {step === 4 && <NewGameStep4 />}
              {step === 5 && <NewGameSuccess />}
            </FormProvider>
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
