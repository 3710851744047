import { useDebounce } from '@uidotdev/usehooks';
import { useWeb3React } from '@web3-react/core';
import { useConnectedWalletIcon } from 'hooks/useConnectedWalletIcon';
import { ChangeEvent, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { allGamesStore } from 'screens/AllGames';
import { useSortAndFiltersStore } from 'screens/Dashboard/store/sortAndFilters';
import { useDashboardStore } from 'screens/Dashboard/store/store';
import { hostedGamesStore } from 'screens/HostedGames/store';
import { myGamesStore } from 'screens/MyGames/store';
import { gameService } from 'services/games';
import { Icons as GlobalIcons } from 'ui-kit/Icons';
import { Icons as SocialIcons } from '../../../Landing/components/Icons';
import { ConnectWalletDialog } from '../ConnectWalletDialog';
import { Icons } from '../Icons';
import { NewGameDialog } from '../NewGameDialog';
import { WalletSettingsPopover } from '../WalletSettings';
import './styles.css';
import { AnalyticsDialog } from '../AnalyticsDialog';

export function DashboardHeader() {
  const wallet = useConnectedWalletIcon();
  const clearToasts = useDashboardStore(state => state.clearToasts);
  const {search, setSearch} = useSortAndFiltersStore();
  const debounceSearch = useDebounce(search, 1000);
  const {account, isActive} = useWeb3React();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const isFAQSearch = useRef(false);

  const isOwner = !!account && account === process.env.REACT_APP_OWNER_WALLET;

  useEffect(() => {
    if (!isFAQSearch.current) setSearch('');
  }, [pathname, setSearch]);

  useEffect(() => {
    if ((search !== debounceSearch && search !== '') || debounceSearch === '')
      return;

    const pathname = window.location.href;

    if (pathname.endsWith('/dashboard')) {
      allGamesStore.setState({page: 0});
      gameService.getAllGames({search: search}).catch(console.warn);
    } else if (pathname.endsWith('/hosted')) {
      myGamesStore.setState({page: 0});
      gameService.getHostedGames({search: search}).catch(console.warn);
    } else if (pathname.endsWith('/my')) {
      hostedGamesStore.setState({page: 0});
      gameService.getMyGames({search: search}).catch(console.warn);
    } else if (
      (pathname.endsWith('/faq') || pathname.endsWith('/terms')) &&
      isFAQSearch.current
    ) {
      navigate('/dashboard');
      gameService
        .getAllGames({search: search})
        .then(() => (isFAQSearch.current = false))
        .catch(console.warn);
    }
  }, [debounceSearch, search, navigate]);

  const handleChange = (e: ChangeEvent) => {
    const val = (e.target as HTMLInputElement).value;
    setSearch(val);

    if (pathname.endsWith('/faq') || pathname.endsWith('/terms')) {
      isFAQSearch.current = true;
    }
  };

  return (
    <header className="flex items-center gap-2 px-5 py-4 md:px-10 md:py-7">
      <Link to="/" className="flex md:hidden">
        <GlobalIcons.Logo width={35} height={22} color="black" />
      </Link>
      <div className="flex md:hidden gap-4 ml-2 pr-2 mr-auto">
        <a
          href="https://twitter.com/ponzidev"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center w-6 h-6"
        >
          <SocialIcons.X color="black" />
        </a>
        <a
          href="https://discord.com/invite/9zMaKTaf7Q"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center w-6 h-6"
        >
          <SocialIcons.Discord color="black" />
        </a>
      </div>
      <div className="dashboard-header-search">
        <Icons.Search />
        <input
          type="text"
          placeholder="Search"
          className="dashboard-header-search-input"
          value={search}
          onChange={handleChange}
        />
      </div>
      <div className="hidden md:block">
        <NewGameDialog>
          <button className="btn dashboard-header-action">
            <Icons.Plus />
            New game
          </button>
        </NewGameDialog>
      </div>
      {isOwner && (
        <div className="block md:hidden">
          <AnalyticsDialog>
            <button className="dashboard-header-wallet">
              <Icons.Analitycs fill="black" />
            </button>
          </AnalyticsDialog>
        </div>
      )}
      {!isActive ? (
        <ConnectWalletDialog>
          <button
            className="dashboard-header-wallet"
            onClick={() => clearToasts()}
          >
            <Icons.Wallet />
          </button>
        </ConnectWalletDialog>
      ) : (
        <WalletSettingsPopover>
          <button
            className="dashboard-header-wallet"
            onClick={() => clearToasts()}
          >
            <img src={wallet} alt="Wallet" />
          </button>
        </WalletSettingsPopover>
      )}
    </header>
  );
}
