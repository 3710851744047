import {useWeb3React} from '@web3-react/core';
import {Provider} from 'lib/web3-react/types';
import {getProviderByConnector} from 'lib/web3-react/utils';

const metaMask = require('../assets/wallets/metaMask.png') as string;

export const useConnectedWalletIcon = (): string => {
  const {connector} = useWeb3React();

  const icons: Record<Provider, string> = {
    MetaMask: 'metaMask',
    WalletConnect: 'walletConnect',
    Coinbase: 'coinbase',
  };

  return connector
    ? (require(
        `../assets/wallets/${icons[getProviderByConnector(connector)]}.png`
      ) as string)
    : metaMask;
};
