import {useWeb3React} from '@web3-react/core';
import {useState} from 'react';

export const useSignMessage = () => {
  const [isSigning, setIsSigning] = useState(false);
  const {provider, account} = useWeb3React();

  const signMessage = async (message: string) => {
    if (!account) {
      throw new Error('No account found');
    }

    if (!provider) {
      throw new Error('No provider found');
    }

    setIsSigning(true);
    const data = await provider.getSigner(account).signMessage(message);
    setIsSigning(false);
    return data;
  };

  return {
    isSigning,
    signMessage,
  };
};
