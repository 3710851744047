import * as Dialog from '@radix-ui/react-dialog';
import {motion} from 'framer-motion';
import './styles.css';
import {variants} from './variants';

interface DialogProps extends Dialog.DialogProps {
  children: React.ReactNode;
}

export function DialogRoot({children, ...props}: DialogProps) {
  return <Dialog.Root {...props}>{children}</Dialog.Root>;
}

interface DialogPortalProps extends Dialog.DialogPortalProps {
  children: React.ReactNode;
  contentProps?: Dialog.DialogContentProps;
  variant?: keyof typeof variants;
}

export function DialogPortal({
  children,
  contentProps,
  variant = 'DEFAULT',
  ...props
}: DialogPortalProps) {
  return (
    <Dialog.Portal {...props}>
      <Dialog.Overlay asChild>
        <motion.div
          key="dialog-overlay"
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-60 z-40"
        />
      </Dialog.Overlay>
      <Dialog.Content
        className={variants[variant].container}
        style={{pointerEvents: 'none'}}
        {...contentProps}
      >
        <motion.div
          layout
          {...variants[variant].animation}
          transition={variant === 'mobile' ? {duration: 0.2} : undefined}
          className={variants[variant].content}
        >
          {children}
        </motion.div>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
