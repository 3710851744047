import {create} from 'zustand';

export type Sort = 'newest' | 'oldest' | 'mostPopular' | 'leastPopular';

interface SortAndFilterStore {
  search: string;
  setSearch: (search: string) => void;
}

export const sortAndFiltersStore = create<SortAndFilterStore>(set => ({
  search: '',
  setSearch: search => set({search}),
}));

export const useSortAndFiltersStore = sortAndFiltersStore;
