import {DialogClose} from '@radix-ui/react-dialog';
import {Link} from 'react-router-dom';

const depositSrc = require('assets/deposit.png') as string;

export function GameDepositSuccess() {
  return (
    <div className="flex flex-col gap-8 px-5 pt-[3.75rem] pb-5 sm:px-[6.25rem] sm:py-[3.75rem]">
      <div className="flex flex-col items-center gap-6 max-w-[369px]">
        <div className="w-[181px] h-[96px] sm:w-[237px] sm:h-[126px] overflow-hidden">
          <img src={depositSrc} className="" alt="Deposit" />
        </div>
        <h2 className="text-center text-xl leading-6 text-[#101520] sm:text-[1.75rem] md:leading-9">
          Congrats! Your deposit has been added
        </h2>
      </div>
      <p className="max-w-[369px] font-medium text-center text-sm text-[#8C8C8C] leading-5">
        Your deposit has been successfully added. You will be able to withdraw once your progress is complete. To fill progress, you need new players to deposit. If nobody joins after you, you will win the jackpot.
      </p>
      <div className="flex flex-col justify-center items-center gap-6">
        <DialogClose asChild>
          <Link to="/dashboard/my" className="btn w-[266px]">
            Go to My games
          </Link>
        </DialogClose>
      </div>
    </div>
  );
}
