import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icons } from '../Icons';
import { getAllGames } from './mock';
import './styles.css';

export function LandingGames() {
  const [games, setGames] = useState(getAllGames(5));

  useEffect(() => {
    const handleResize = () => {
      const limit = Math.trunc(window.innerWidth / 200);

      setGames(getAllGames(Math.max(5, limit)));
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="landing-games" id="games">
      <h2 className="landing-heading landing-games-heading">Ponzi games</h2>
      <Link to="/dashboard" className="landing-games-action">
        View all games
        <Icons.ArrowRight />
      </Link>
      
    </div>
  );
}
