import {useDebounce} from '@uidotdev/usehooks';
import {useLayoutEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useSortAndFiltersStore} from 'screens/Dashboard';
import {gameService} from 'services/games';
import {GamesHeader} from 'ui-kit/GamesHeader';
import {Icons} from 'ui-kit/Icons';
import {AllGameCard} from './components/AllGameCard';
import {useAllGamesStore} from './store';

export function AllGamesView() {
  const {games, isLoading, page, hasMore, totalGames, setHasMore, setPage} =
    useAllGamesStore();
  const {search} = useSortAndFiltersStore();
  const debounceSearch = useDebounce(search, 1000);

  useLayoutEffect(() => {
    if (search !== '') return;

    setPage(0);
    gameService.getAllGames().catch(err => {
      console.warn(err);
    });
  }, [setPage]);

  const handleNext = () => {
    setPage(page + 1);
    gameService
      .getAllGames({search: debounceSearch, addToExisting: true})
      .then(res => setHasMore(res.length > 0))
      .catch(err => {
        console.warn(err);
      });
  };

  return (
    <>
      <GamesHeader
        heading="All Games"
        gamesLength={totalGames}
        isLoading={isLoading}
      />
      {isLoading && (
        <div className="flex items-center justify-center w-full my-8 text-[#A8A8A8]">
          <Icons.Spinner className="animate-spin" color="#A8A8A8" />
        </div>
      )}
      <div className="grid grid-cols-1 auto-rows-fr gap-6 pb-6 min-[1300px]:auto-rows-[11rem]">
        {!isLoading && games.length === 0 && (
          <div className="flex items-center justify-center w-full text-[#A8A8A8]">
            No games
          </div>
        )}
        {!isLoading && games.length > 0 && (
          <InfiniteScroll
            next={handleNext}
            hasMore={hasMore}
            scrollableTarget="scroll-container"
            loader={
              <div className="flex items-center justify-center w-full my-8 text-[#A8A8A8]">
                <Icons.Spinner className="animate-spin" color="#A8A8A8" />
              </div>
            }
            dataLength={games.length}
            className="grid grid-cols-1 auto-rows-fr gap-6 pb-6 min-[1380px]:auto-rows-[11rem]"
          >
            {games.map((game, i) => (
              <AllGameCard key={i} game={game} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
}
