export const mockGames = [
  {
    imageSrc: require('../../assets/Game1.jpg') as string,
    name: 'Just Deposit IT',
    lastBet: '10 min ago',
    users: '7K',
    roi: '60%',
  },
  {
    imageSrc: require('../../assets/Game2.jpg') as string,
    name: 'Check your luck',
    lastBet: '48 min ago',
    users: '2K',
    roi: '35%',
  },
  {
    imageSrc: require('../../assets/Game3.jpg') as string,
    name: 'Opportunity Marathon',
    lastBet: '5 min ago',
    users: '600',
    roi: '100%',
  },
  {
    imageSrc: require('../../assets/Game4.jpg') as string,
    name: 'Symphony of Success',
    lastBet: '11 min ago',
    users: '3K',
    roi: '71%',
  },
];

export function getAllGames(limit?: number) {
  if (!limit || limit === mockGames.length) {
    return mockGames;
  }

  if (limit < mockGames.length) {
    return [...mockGames].slice(0, limit);
  }

  if (limit > mockGames.length) {
    const diff = limit - mockGames.length;
    const newGames = [...mockGames];

    for (let i = 0; i < diff; i++) {
      newGames.push(mockGames[i % mockGames.length]);
    }

    return newGames;
  }

  return mockGames;
}
