import {Icons} from 'ui-kit/Icons';
import {variants} from './variants';

interface ProgressBarProps {
  current: number;
  total: number;
  variant?: keyof typeof variants;
}

export function ProgressBar({
  current,
  total,
  variant = 'DEFAULT',
}: ProgressBarProps) {
  return (
    <div className={variants[variant].container}>
      <div className="flex justify-between w-full text-xs leading-normal">
        {variant === 'DEFAULT' && (
          <>
            <span>{current}%</span>
            <span>{total}%</span>
          </>
        )}
        {variant === 'small' && (
          <span className="ml-auto">
            {current}/{total}%
          </span>
        )}
      </div>
      <div className={variants[variant].bar}>
        <div
          className={variants[variant].barProgress}
          style={{
            width: `${(current / total) * 100}%`,
            background: current >= total ? '#9BCB97' : '#000000',
          }}
        />
        {current >= total && (
          <div className="absolute top-1/2 right-0 -translate-y-1/2 flex justify-center items-center w-4 h-4 rounded-full bg-[#A2CB9E]">
            <Icons.TickSm />
          </div>
        )}
      </div>
    </div>
  );
}
