import {useWeb3React} from '@web3-react/core';
import {formatEther} from 'ethers/lib/utils';
import {useAllGamesStore} from 'screens/AllGames/store';
import {gameService} from 'services/games';
import {Game, GameStatus} from 'types/Game';
import {EthereumValue} from 'ui-kit/EthereumValue';
import {GameButton} from 'ui-kit/GameButton';
import {GameCardLg} from 'ui-kit/GameCardLg';
import './styles.css';

export interface GameCardLgProps {
  game: Game;
}

export function AllGameCard({game}: GameCardLgProps) {
  const {
    name,
    createdAt,
    thumbnailUrl,
    softPool,
    roi,
    minDeposit,
    maxDeposit,
    membersCount,
    hostFee,
    platformFee,
    bets,
  } = game;
  const numberOfDeposits = bets.length;
  const setGame = useAllGamesStore(s => s.setGame);
  const date = new Date(createdAt).toLocaleDateString('en-UK', {
    day: 'numeric',
    month: 'short',
  });

  const time = new Date(createdAt).toLocaleTimeString('en-UK', {
    hour: 'numeric',
    minute: 'numeric',
  });

  const {isActive} = useWeb3React();

  const handleGameRefetch = async () => {
    if (!isActive) return;
    const res = await gameService.getGameById(game.id);
    if (!res) return;

    setGame(res);
  };

  return (
    <GameCardLg
      game={game}
      imgSrc={thumbnailUrl}
      gridValues={[
        {
          label: 'Current pool',
          value: <EthereumValue value={+formatEther(softPool)} />,
        },
        {
          label: 'ROI',
          value: `${roi}%`,
        },
        {
          label: 'Fee',
          value: `${hostFee + platformFee}%`,
        },
        {
          label: 'Min. limit',
          value: <EthereumValue value={minDeposit} />,
        },
        {
          label: 'Max. limit',
          value: <EthereumValue value={maxDeposit} />,
        },
      ]}
      action={
        <div className="flex flex-col items-center gap-4 pb-1 md:pb-0">
          <GameButton
            status={GameStatus.AWAITING_DEPOSIT}
            game={game}
            refetchGame={() => {
              handleGameRefetch().catch(console.warn);
            }}
          />
        </div>
      }
    >
      <span className="block mb-2 game-card-lg-createdAt lg:hidden">
        <span className="game-card-lg-muted">Created</span> {date} at {time}
      </span>
      <div className="flex w-full justify-between items-center gap-2 h-full lg:w-auto lg:flex-col lg:items-start">
        <div className="flex flex-col items-start gap-2">
          <h5 className="w-[150px] text-base line-clamp-2 lg:w-full min-[1380px]:w-[300px] md:text-lg lg:line-clamp-1">
            {name}
          </h5>
          <span className="hidden game-card-lg-createdAt lg:block">
            <span className="game-card-lg-muted">Created</span> {date} at {time}
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <div className="text-sm font-medium">
            {`${numberOfDeposits} ${numberOfDeposits === 1 ? 'deposit' : 'deposits'}`}
          </div>
        </div>
      </div>
    </GameCardLg>
  );
}
