import {ReactNode} from 'react';

interface InstructionProps {
  currentStep: number;
  steps: number;
  heading: ReactNode;
  content: string;
  imageSrc: string;
}

export function Instruction({
  currentStep,
  steps,
  heading,
  content,
  imageSrc,
}: InstructionProps) {
  return (
    <div className="flex flex-col w-full gap-x-2 gap-y-10 mx-auto bg-[#f3f3f3] lg:grid lg:grid-cols-2 lg:px-3 lg:gap-y-0">
      <div className="instruction-content">
        <div className="instruction-step">
          {currentStep} of {steps} strategies
        </div>
        <h2 className="instruction-heading">{heading}</h2>
        <p className="instruction-text">{content}</p>
      </div>
      <img
        src={imageSrc}
        alt=""
        className="instruction-image my-none lg:my-auto"
      />
    </div>
  );
}
