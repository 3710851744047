import {
  Content as AccordionContent,
  Item as AccordionItem,
  AccordionItemProps,
  Trigger as AccordionTrigger,
} from '@radix-ui/react-accordion';
import {ReactNode} from 'react';
import {Icons} from '../Icons';
import './styles.css';

interface FAQAccordionProps extends AccordionItemProps {
  heading: ReactNode;
}

export function FAQAccordionItem({
  heading,
  children,
  ...props
}: FAQAccordionProps) {
  return (
    <AccordionItem {...props} className="p-1 rounded-xl bg-white">
      <AccordionTrigger className="flex justify-between items-center gap-2 w-full p-4 text-left text-sm">
        <span className="block max-w-[412px]">{heading}</span>
        <span className="faq-accordion-trigger">
          <Icons.Plus />
        </span>
      </AccordionTrigger>
      <AccordionContent className="faq-accordion-content">
        <div className="m-4 mt-0">{children}</div>
      </AccordionContent>
    </AccordionItem>
  );
}
