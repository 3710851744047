import {DialogTitle} from '@radix-ui/react-dialog';
import {useChain} from 'hooks/useChain';
import {TARGET_CHAIN} from 'lib/web3-react/constants/chains';
import {Controller, useFormContext} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {FormCheckbox} from 'ui-kit/FormCheckbox';
import {Icons} from 'ui-kit/Icons';

export function NewGameMain() {
  const formCtx = useFormContext();
  const isRulesAccepted = formCtx.watch('isRulesAccepted') as boolean;
  const {chainId, switchChain} = useChain();

  const handleNext = () => {
    if (!isRulesAccepted) return;

    if (chainId !== TARGET_CHAIN.chainId) {
      switchChain(TARGET_CHAIN.chainId)
        .then(() => {
          formCtx.setValue('step', 1);
        })
        .catch(console.warn);
    } else {
      formCtx.setValue('step', 1);
    }
  };

  return (
    <div
      className="flex flex-col items-center gap-8 px-5 pt-[6.25rem] pb-[2.375rem] text-center font-medium rounded-[2rem] bg-contain bg-no-repeat sm:px-[6.375rem] sm:pt-[11.125rem] sm:pb-[3.125rem]"
    >
      <DialogTitle className="dialog-title">Host a game</DialogTitle>
      <p className="max-w-[436px] text-sm text-[#8C8C8C]">
        To activate the game, you will need to make the initial deposit. You will not lose it if nobody joins.
      </p>
      <Controller
        name="isRulesAccepted"
        control={formCtx.control}
        render={({field}) => (
          <FormCheckbox
            label="I confirm that I have read the Terms of Service and agree to them"
            className="text-[#8C8C8C]"
            {...field}
          />
        )}
      />
      <div className="flex flex-col justify-center items-center gap-6">
        <button
          className="btn"
          disabled={!isRulesAccepted}
          onClick={handleNext}
        >
          <Icons.Plus />
          New game
        </button>
        <Link to="/terms" target="_blank" className="text-sm">
          Terms of Service
        </Link>
      </div>
    </div>
  );
}
