import {useFormContext, useFormState} from 'react-hook-form';

interface FormMessageProps {
  name: string;
}

export function FormMessage({name}: FormMessageProps) {
  const formCtx = useFormContext();

  if (!formCtx) {
    throw new Error(
      'FormMessage component should be used inside FormProvider component.'
    );
  }

  const formState = useFormState({
    control: formCtx.control,
    name,
  });

  const errors = formState.errors[name];

  if (!errors) {
    return null;
  }

  return (
    <span className="text-xs text-red-500">{errors.message as string}</span>
  );
}
