export const variants = {
  DEFAULT: {
    container:
      'fixed top-0 left-0 flex justify-center items-center w-screen h-screen p-4 z-50',
    content:
      'max-w-full max-h-full rounded-[2rem] bg-white font-body overflow-pseudo-hidden pointer-events-auto',
    animation: {
      initial: {
        width: 'auto',
        height: 'auto',
        scale: 0.5,
        opacity: 0,
      },
      animate: {
        width: 'auto',
        height: 'auto',
        scale: 1,
        opacity: 1,
      },
      exit: {
        width: 'auto',
        height: 'auto',
        scale: 0.5,
        opacity: 0,
      },
    },
  },
  mobile: {
    container: 'fixed top-[162px] bottom-0 left-0 right-0 z-50',
    content:
      'w-full h-full rounded-t-[1.25rem] bg-white font-body overflow-pseudo-hidden pointer-events-auto',
    animation: {
      initial: {
        width: 'auto',
        height: '100%',
        y: '100%',
      },
      animate: {
        width: 'auto',
        height: '100%',
        y: 0,
      },
      exit: {
        width: 'auto',
        height: '100%',
        y: '100%',
      },
    },
  },
};
