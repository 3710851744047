export const MIN_DEPOSIT = 0.001;
export const MAX_DEPOSIT = 1000;

export const GAMES_PER_PAGE = 8;

export const apiUrls = {
  development: 'https://ponzi-backend-dev.up.railway.app/',
  staging: 'https://ponzi-backend-stage.up.railway.app/',
  production: 'https://api.ponzi.market/',
};

export const gameContracts = {
  development: {
    gameFactory: '0x519d76A95545ef8c230505858305D23f3E1cff76',
  },
  staging: {
    gameFactory: '0xfCd5dA8c2682e5d17235A5010A01bf6B51B2841D',
  },
  production: {
    gameFactory: '0xfCd5dA8c2682e5d17235A5010A01bf6B51B2841D',
  },
};

export const STAGE = (process.env.REACT_APP_STAGE ||
  'development') as keyof typeof apiUrls;

export const API_BASE_URL = apiUrls[STAGE];
export const GAME_CONTRACTS = gameContracts[STAGE];
