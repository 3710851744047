import {DialogClose, DialogTitle} from '@radix-ui/react-dialog';
import {ReactNode, useEffect, useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormField} from 'ui-kit/FormField';
import {FormImage} from 'ui-kit/FormImage';
import {FormMessage} from 'ui-kit/FormMessage';
import {Icons} from 'ui-kit/Icons';
import {NewGameFormScheme, NewGameFormType} from './scheme';
import {ZodError} from 'zod';

interface NewGameStepProps {
  step: number;
  steps: number;
  title: string;
  children: ReactNode;
}

export function NewGameStep({step, steps, title, children}: NewGameStepProps) {
  return (
    <div className="flex flex-col gap-8 px-5 py-8 sm:gap-[50px] sm:px-[60px] sm:py-[50px]">
      <div className="flex flex-col gap-6 text-center">
        <div className="flex justify-center items-center mx-auto px-4 py-1.5 bg-[#F9F9F9] rounded-[20px] text-xs leading-[0.875rem] text-[#7c7c7c] sm:text-sm sm:leading-6">
          {step} of {steps} steps
        </div>
        <DialogTitle className="dialog-title">{title}</DialogTitle>
      </div>
      {children}
    </div>
  );
}

export function NewGameStep1() {
  const formCtx = useFormContext<NewGameFormType>();
  const name = formCtx.watch('name');
  const thumbnail = formCtx.watch('thumbnail');

  const isInvalid = useMemo(
    () =>
      NewGameFormScheme.pick({name: true, thumbnail: true}).safeParse({
        name,
        thumbnail,
      }).success === false,
    [name, thumbnail]
  );

  useEffect(() => {
    formCtx.clearErrors('thumbnail');
    try {
      NewGameFormScheme.pick({thumbnail: true}).parse({
        thumbnail,
      }).thumbnail;
    } catch (error) {
      if (error instanceof ZodError) {
        formCtx.setError('thumbnail', {
          type: 'custom',
          message: error.errors[0].message,
        });
      }
    }
  }, [thumbnail]);

  const handleNext = () => {
    if (isInvalid) return;

    formCtx.setValue('step', 2);
  };

  return (
    <NewGameStep step={1} steps={4} title="Game details">
      <div className="flex flex-col gap-8 sm:w-[406px]">
        <div className="grid gap-2">
          <FormField
            label="Create game name"
            placeholder="E.g. Cool Game"
            {...formCtx.register('name')}
          />
          <FormMessage name="name" />
        </div>
        <div className="grid gap-2">
          <Controller
            name="thumbnail"
            control={formCtx.control}
            render={({field}) => <FormImage label="Add thumbnail" {...field} />}
          />
          <FormMessage name="thumbnail" />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <button className="btn" disabled={isInvalid} onClick={handleNext}>
          Next
          <Icons.ArrowRight />
        </button>
        <DialogClose className="text-sm">Cancel</DialogClose>
      </div>
    </NewGameStep>
  );
}
