import {useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormField} from 'ui-kit/FormField';
import {FormMessage} from 'ui-kit/FormMessage';
import {FormRange} from 'ui-kit/FormRange';
import {Icons} from 'ui-kit/Icons';
import {NewGameFormScheme, NewGameFormType, refiners} from './scheme';
import {NewGameStep} from './NewGameStep1';
import {FieldEthereumIcon} from 'ui-kit/FieldEthereumIcon';
import {Tooltip} from 'ui-kit/Tooltip';

export function NewGameStep2() {
  const formCtx = useFormContext<NewGameFormType>();
  const minDeposit = formCtx.watch('minDeposit');
  const maxDeposit = formCtx.watch('maxDeposit');
  const roi = formCtx.watch('roi');
  const fee = formCtx.watch('fee');

  const isInvalid = useMemo(
    () =>
      NewGameFormScheme.pick({
        minDeposit: true,
        maxDeposit: true,
        roi: true,
        fee: true,
      })
        .refine(refiners.minMaxDeposit)
        .safeParse({
          minDeposit,
          maxDeposit,
          roi,
          fee,
        }).success === false,
    [minDeposit, maxDeposit, roi, fee]
  );

  const handlePrev = () => {
    formCtx.resetField('minDeposit');
    formCtx.resetField('maxDeposit');
    formCtx.resetField('roi');
    formCtx.resetField('fee');
    formCtx.setValue('step', 1);
  };

  const handleNext = () => {
    if (isInvalid) return;

    formCtx.setValue('step', 3);
  };

  return (
    <NewGameStep step={2} steps={4} title="Game settings">
      <div className="flex flex-col -mb-8 gap-6 w-[calc(100vw-5rem)] sm:w-[406px]">
        <div className="grid gap-2">
          <FormField
            type="number"
            label="Minimum deposit limit"
            placeholder="0.001 ETH"
            leftIcon={<FieldEthereumIcon />}
            min={0.001}
            max={maxDeposit || 1000}
            {...formCtx.register('minDeposit', {
              valueAsNumber: true,
            })}
          />
          <FormMessage name="minDeposit" />
        </div>
        <div className="grid gap-2">
          <FormField
            type="number"
            label="Maximum deposit limit"
            placeholder="1000 ETH"
            leftIcon={<FieldEthereumIcon />}
            min={minDeposit || 0.001}
            max={1000}
            {...formCtx.register('maxDeposit', {
              valueAsNumber: true,
            })}
          />
          <FormMessage name="maxDeposit" />
        </div>
        <div className="grid gap-2">
          <Controller
            name="roi"
            control={formCtx.control}
            render={({field}) => (
              <FormRange
                label="ROI %"
                min={1}
                max={100}
                minLabel="1%"
                maxLabel="100%"
                tooltipPostfix="%"
                {...field}
              />
            )}
          />
          <FormMessage name="roi" />
        </div>
        <div className="grid gap-2">
          <Controller
            name="fee"
            control={formCtx.control}
            render={({field}) => (
              <FormRange
                label={
                  <span className="flex items-center gap-2">
                    <span>Game fee %</span>
                    <Tooltip
                      content={
                        <>
                          <span className="text-black">Fees explanation</span>
                          Half goes to you, half goes to platform.
                        </>
                      }
                    >
                      <Icons.Info />
                    </Tooltip>
                  </span>
                }
                minLabel="2%"
                maxLabel="10%"
                tooltipPostfix="%"
                min={2}
                max={10}
                step={0.1}
                {...field}
              />
            )}
          />
          <FormMessage name="fee" />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center -mb-3 sm:-mb-6 gap-6">
        <button className="btn" disabled={isInvalid} onClick={handleNext}>
          Next
          <Icons.ArrowRight />
        </button>
        <button className="flex items-center gap-4" onClick={handlePrev}>
          <div className="rotate-180">
            <Icons.ArrowRight color="black" />
          </div>
          Previous step
        </button>
      </div>
    </NewGameStep>
  );
}
