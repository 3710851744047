import {JsonRpcSigner, TransactionResponse} from '@ethersproject/providers';
import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';
import {gameABI} from 'constants/gameAbi';
import {gameFactoryABI} from 'constants/gameFactoryAbi';
import {BigNumberish, ethers} from 'ethers';
import {allGamesStore} from 'screens/AllGames';
import {hostedGamesStore} from 'screens/HostedGames/store';
import {myGamesStore} from 'screens/MyGames/store';
import {
  Bet,
  BetStatus,
  CreateGameParams,
  Game,
  GameRequestOptions,
  GamesResponse,
  WithdrawResponse,
} from 'types/Game';
import {API_BASE_URL, GAMES_PER_PAGE, GAME_CONTRACTS} from '../constants';
import {useDashboardStore} from 'screens/Dashboard/store/store';

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  return config;
};

export class GameService {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_BASE_URL,
      headers: {'Content-Type': 'application/json'},
      withCredentials: true,
    });

    this._api.defaults.headers.put['Content-Type'] = 'application/json';
    this._api.interceptors.request.use((config: InternalAxiosRequestConfig) =>
      requestInterceptor(config)
    );
  }

  async getAllGames({
    limit = GAMES_PER_PAGE,
    addToExisting = false,
    search = '',
  }: GameRequestOptions = {}): Promise<Game[]> {
    try {
      allGamesStore.setState({isLoading: !addToExisting});
      const url = '/api/games';
      const {games, page} = allGamesStore.getState();

      const {data} = await this._api.get<GamesResponse>(url, {
        params: {
          page,
          limit,
          search,
        },
      });

      const newGames = addToExisting ? [...games, ...data.data] : data.data;
      allGamesStore.setState({
        games: newGames,
        hasMore: data.data.length === limit && data.total > newGames.length,
        totalGames: data.total,
      });

      return data.data;
    } catch (error) {
      console.warn(error);
    } finally {
      allGamesStore.setState({isLoading: false});
    }
    return [];
  }

  async getMyGames({
    limit = GAMES_PER_PAGE,
    addToExisting = false,
    search = '',
  }: GameRequestOptions = {}): Promise<Game[]> {
    try {
      myGamesStore.setState({isLoading: !addToExisting});
      const {games, page} = myGamesStore.getState();
      const url = '/api/games/my';

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const {data} = await this._api.get<GamesResponse>(url, {
        params: {
          page,
          limit,
          search,
        },
        headers: {
          authorization: token,
        },
      });

      const newGames = addToExisting ? [...games, ...data.data] : data.data;

      myGamesStore.setState({
        games: newGames,
        hasMore: data.data.length === limit && data.total > newGames.length,
        totalGames: data.total,
      });

      return data.data;
    } catch (error) {
      console.warn(error);
    } finally {
      myGamesStore.setState({isLoading: false});
    }
    return [];
  }

  async getHostedGames({
    limit = GAMES_PER_PAGE,
    addToExisting = false,
    search = '',
  }: GameRequestOptions = {}): Promise<Game[]> {
    try {
      hostedGamesStore.setState({isLoading: !addToExisting});
      const {games, page} = hostedGamesStore.getState();
      const url = '/api/games/hosted';

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const {data} = await this._api.get<GamesResponse>(url, {
        params: {
          page,
          limit,
          search,
        },
        headers: {
          authorization: token,
        },
      });

      const newGames = addToExisting ? [...games, ...data.data] : data.data;
      hostedGamesStore.setState({
        games: newGames,
        hasMore: data.data.length === limit && data.total > newGames.length,
        totalGames: data.total,
      });

      return data.data;
    } catch (error) {
      console.warn(error);
    } finally {
      hostedGamesStore.setState({isLoading: false});
    }
    return [];
  }

  async getGameById(id: Game['id']): Promise<Game | null> {
    try {
      const url = '/api/games/' + id;
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const {data} = await this._api.get<Game>(url, {
        headers: {
          authorization: token,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async uploadFile(fileList: FileList): Promise<string | null> {
    try {
      const url = '/api/files/upload';
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const {data} = await this._api.post<string, {data: string}>(
        url,
        {file: fileList[0]},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: token,
          },
        }
      );

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async createGame(signer: JsonRpcSigner, gameData: CreateGameParams) {
    const {payableAmount, name, file, fee, maxDeposit, minDeposit, roi} =
      gameData;

    const contract = new ethers.Contract(
      GAME_CONTRACTS.gameFactory,
      gameFactoryABI,
      signer
    );

    // ! ethers does not provide generic types
    // eslint shows warnings because of assigning BigNumber to any type

    // eslint-disable @typescript-eslint/no-unsafe-assignment
    const tx = (await contract.createGame(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      fee,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      maxDeposit,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      minDeposit,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      roi,
      file,
      name,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      {value: payableAmount}
    )) as TransactionResponse;
    await tx?.wait();
  }

  async deposit(
    signer: JsonRpcSigner,
    contractAddress: string,
    payableAmount: BigNumberish
  ) {
    try {
      const contract = new ethers.Contract(contractAddress, gameABI, signer);

      const tx = (await contract.deposit({
        value: payableAmount,
      })) as TransactionResponse;

      await tx.wait();
      return true;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getWithdrawBet(
    gameId: Game['id'],
    betId: Bet['id'],
    wallet: string,
    isJackpot = false
  ) {
    try {
      const url = isJackpot
        ? '/api/bets/withdraw/jackpot'
        : '/api/bets/withdraw';
      const {data} = await this._api.get<WithdrawResponse>(url, {
        params: {
          gameId: gameId,
          betId: betId,
          wallet,
        },
      });

      return data;
    } catch (error) {
      const {addToast} = useDashboardStore.getState();
      if (error instanceof AxiosError) {
        addToast({
          status: 'error',
          children: error.response?.data?.message || error.message,
        });
      } else if (error instanceof Error) {
        addToast({
          status: 'error',
          children: error.message || 'Oops, something went wrong',
        });
      }
    }
    return null;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async withdraw(game: Game, bet: Bet, wallet: string, signer: JsonRpcSigner) {
    const isJackpot = bet.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE;

    const url = isJackpot ? '/api/bets/withdraw/jackpot' : '/api/bets/withdraw';
    const {data} = await this._api.get<WithdrawResponse>(url, {
      params: {
        gameId: game.id,
        betId: bet.id,
        wallet,
      },
    });

    if (!data) throw new Error('No data found for withdraw request');

    const contract = new ethers.Contract(game.contractAddress, gameABI, signer);

    const tx = (await contract.withdraw(
      isJackpot,
      bet.id,
      data.data.amount,
      data.data.nonce,
      data.signarute
    )) as TransactionResponse;

    await tx.wait();

    return data;
  }
}

export const gameService = new GameService();
