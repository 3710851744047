import {DialogTrigger} from '@radix-ui/react-dialog';
import {AnimatePresence} from 'framer-motion';
import {ReactNode, useEffect, useState} from 'react';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {ConnectWalletDialogContent} from './ConnectWalletDialogContent';

interface ConnectWalletDialogProps {
  children?: ReactNode;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  onWalletConnected?: () => void;
}

export function ConnectWalletDialog({
  children,
  ...props
}: ConnectWalletDialogProps) {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);
  const [isLocked, setIsLocked] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(props.isOpen ?? false);
  }, [props.isOpen]);

  const handleDialogClose = (isOpen: boolean) => {
    setIsOpen(isOpen);
    props.onOpenChange?.(isOpen);
  };

  const handleWalletConnect = () => {
    setIsOpen(false);
    props.onOpenChange?.(false);
    props.onWalletConnected?.();
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={handleDialogClose}>
      {!!children && <DialogTrigger asChild>{children}</DialogTrigger>}
      <AnimatePresence>
        {isOpen && (
          <DialogPortal
            forceMount
            contentProps={{
              onInteractOutside: e => {
                if (isLocked) e.preventDefault();
              },
            }}
          >
            <ConnectWalletDialogContent
              onLockChange={val => setIsLocked(val)}
              onWalletConnect={handleWalletConnect}
            />
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
