import {zodResolver} from '@hookform/resolvers/zod';
import {DialogTrigger} from '@radix-ui/react-dialog';
import {useWeb3React} from '@web3-react/core';
import {errors} from 'ethers';
import {parseEther} from 'ethers/lib/utils';
import {AnimatePresence} from 'framer-motion';
import {useChain} from 'hooks/useChain';
import {TARGET_CHAIN} from 'lib/web3-react/constants/chains';
import {FormEvent, ReactNode, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {ConnectWalletDialog} from 'screens/Dashboard/components/ConnectWalletDialog';
import {useDashboardStore} from 'screens/Dashboard/store/store';
import {gameService} from 'services/games';
import {EthersError} from 'types';
import {Game} from 'types/Game';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {GameDepositForm} from './GameDepositForm';
import {GameDepositSuccess} from './GameDepositSuccess';
import {GameDepositScheme, GameDepositType} from './scheme';

interface GameDepositDialogProps {
  children: ReactNode;
  game: Game;
  onSuccessfulDeposit?: () => void;
}

export function GameDepositDialog({
  children,
  game,
  onSuccessfulDeposit,
}: GameDepositDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<'deposit' | 'success'>('deposit');
  const {isActive} = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const {addToast} = useDashboardStore();
  const [error, setError] = useState<string | null>(null);

  const form = useForm<GameDepositType>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(
      GameDepositScheme.refine(
        data =>
          data.deposit >= game.minDeposit && data.deposit <= game.maxDeposit,
        {
          path: ['deposit'],
          message: `Deposit must be between ${game.minDeposit} and ${game.maxDeposit}`,
        }
      )
    ),
  });

  const {account, provider} = useWeb3React();
  const {chainId, switchChain} = useChain();

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);

    if (!open) return;
    if (step === 'success') onSuccessfulDeposit?.();
    setStep('deposit');
    form.reset();
  };

  const handleDeposit = async (e: FormEvent) => {
    e.preventDefault();

    if (!form.formState.isValid) return;

    try {
      setIsLoading(true);
      setError(null);
      if (chainId !== TARGET_CHAIN.chainId) {
        await switchChain(TARGET_CHAIN.chainId);
        addToast({
          status: 'success',
          children: 'Your network has been changed to a recommended one',
        });
      }

      const signer = provider?.getSigner(account);

      if (!signer) {
        throw new Error('No signer');
      }

      const {deposit} = form.getValues();
      const parsedDeposit = parseEther(deposit.toString());

      const balance = await signer.getBalance();

      if (!balance) {
        throw new Error('No balance');
      }

      if (balance.lt(parsedDeposit)) {
        form.setError('deposit', {
          type: 'custom',
          message: 'Insufficient balance',
        });
        return;
      }

      const res = await gameService.deposit(
        signer,
        game.contractAddress,
        parsedDeposit
      );

      if (!res) throw new Error('Deposit failed');

      setStep('success');
    } catch (error) {
      const ethersError = error as EthersError;
      if (ethersError.code === errors.ACTION_REJECTED) {
        setError(
          'Transaction Rejected: It appears you have declined the transaction.'
        );
        return;
      }

      if (ethersError.code === errors.NETWORK_ERROR) return;

      if ('reason' in ethersError) {
        addToast({
          status: 'error',
          children: ethersError.reason || 'Oops, something went wrong',
        });
      } else if (error instanceof Error) {
        addToast({
          status: 'error',
          children: error.message || 'Oops, something went wrong',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isActive)
    return (
      <ConnectWalletDialog onWalletConnected={() => handleOpenChange(true)}>
        {children}
      </ConnectWalletDialog>
    );

  return (
    <DialogRoot open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal
            forceMount
            contentProps={{
              onOpenAutoFocus: e => e.preventDefault(),
              onInteractOutside: e => {
                if (isLoading) e.preventDefault();
              },
            }}
          >
            {step === 'deposit' && (
              <form
                action="POST"
                onSubmit={e => {
                  handleDeposit(e).catch(console.warn);
                }}
              >
                <FormProvider {...form}>
                  <GameDepositForm
                    game={game}
                    isLoading={isLoading}
                    error={error}
                  />
                </FormProvider>
              </form>
            )}
            {step === 'success' && <GameDepositSuccess />}
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
