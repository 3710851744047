import {motion, useScroll, useTransform} from 'framer-motion';
import {useIsSmall} from 'lib/useBreakpoints';
import {Link} from 'react-router-dom';
import {Icons} from '../Icons';
import {LandingCode} from '../LandingCode';
import './styles.css';

export function LandingHero() {
  const isSmall = useIsSmall();
  const {scrollY} = useScroll();
  const offset = useTransform(scrollY, [0, 20], [isSmall ? 20 : 10, 0]);
  const borderRadius = useTransform(scrollY, [0, 20], [isSmall ? 32 : 20, 0]);

  return (
    <div className="relative w-full p-2.5 sm:p-5">
      <motion.div
        className="hero-bg"
        style={{
          top: offset,
          left: offset,
          right: offset,
          bottom: 0,
          borderRadius,
        }}
      />
      <div className="hero">
        <div className="hero-content">
          <h1 className="hero-content-heading">
            Host or Join a
            <br />
            Trustless Ponzi
          </h1>
          <p className="hero-content-text">
            Classic Ponzi mechanics. Except everything's transparent.
          </p>
          <Link
            to="/dashboard"
            className="flex justify-center items-center w-40 h-[3.125rem] mb-[3.125rem] rounded-xl bg-white font-medium text-sm text-black sm:h-[3.375rem] sm:mb-[3.375rem]"
          >
            Open app
          </Link>
          <LandingCode />
          <div className="flex gap-15 mt-8">
            <a
              href="https://docs.ponzi.market/"
              target="_blank"
              rel="noopener noreferrer"
              className="hero-content-link"
            >
              <Icons.Docs />
              Docs
            </a>
            <a
              href="https://github.com/swagamoney/ponzi.contracts"
              target="_blank"
              rel="noopener noreferrer"
              className="hero-content-link"
            >
              <Icons.GitHub />
              Github
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
