import {z} from 'zod';
import {ACCEPTED_MIME_TYPES, MAX_FILE_SIZE} from './constants';
import {MAX_DEPOSIT, MIN_DEPOSIT} from '../../../../constants';

export const NewGameFormScheme = z.object({
  step: z.number().min(0).max(5),
  isLoading: z.boolean(),

  isRulesAccepted: z.boolean(),

  name: z.string().min(1, 'Name is required.'),
  roi: z.coerce.number().min(1).max(100),
  minDeposit: z.coerce
    .number({
      invalid_type_error: 'Min deposit should be a number.',
    })
    .min(MIN_DEPOSIT)
    .max(MAX_DEPOSIT),
  maxDeposit: z.coerce
    .number({
      invalid_type_error: 'Max deposit should be a number.',
    })
    .min(MIN_DEPOSIT)
    .max(MAX_DEPOSIT),
  fee: z.coerce.number().min(2).max(10).positive(),
  thumbnail: z
    .instanceof(FileList)
    .refine(fileList => fileList.length === 1, 'Only one file is allowed.')
    .refine(
      fileList => fileList[0].size < MAX_FILE_SIZE,
      `Image too large. Max size is ${MAX_FILE_SIZE / 1024 / 1024} MB.`
    )
    .refine(
      fileList => ACCEPTED_MIME_TYPES.includes(fileList[0].type),
      'Only jpeg, png, heic are allowed.'
    )
    .nullish(),

  firstDeposit: z.coerce.number({
    invalid_type_error: 'First deposit is required.',
  }),
});

export type NewGameFormType = z.infer<typeof NewGameFormScheme>;

export const refiners = {
  minMaxDeposit: (data: Pick<NewGameFormType, 'minDeposit' | 'maxDeposit'>) => {
    return data.minDeposit < data.maxDeposit;
  },
  firstDeposit: (
    data: Pick<NewGameFormType, 'firstDeposit' | 'minDeposit' | 'maxDeposit'>
  ) => {
    return (
      data.firstDeposit >= (data.minDeposit || MIN_DEPOSIT) &&
      data.firstDeposit <= (data.maxDeposit || MAX_DEPOSIT)
    );
  },
};

export const NewGameFormSchemeFull = NewGameFormScheme.extend({}).superRefine(
  (data, ctx) => {
    if (!refiners.minMaxDeposit(data)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Max deposit should be greater than min deposit.',
        path: ['maxDeposit'],
      });
    }

    if (!refiners.firstDeposit(data)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Deposit must be between ${
          data.minDeposit || MIN_DEPOSIT
        } and ${data.maxDeposit || MAX_DEPOSIT}.`,
        path: ['firstDeposit'],
      });
    }
  }
);
