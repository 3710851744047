import {buildCoinbaseWalletConnector} from '../coinbase';
import {Connection} from '../connections';
import {buildInjectedConnector} from '../injected';
import {Provider} from '../types';
import {buildWalletConnectConnector} from '../walletConnect';

export const isMainNet = process.env.REACT_APP_MAINNET === 'true';

export const PRIORITIZED_CONNECTORS: {[key in Provider]: Connection} = {
  MetaMask: buildInjectedConnector(),
  WalletConnect: buildWalletConnectConnector(),
  Coinbase: buildCoinbaseWalletConnector(),
};
