import {useSortAndFiltersStore} from 'screens/Dashboard';
import {Icons} from 'screens/Dashboard/components/Icons';
import './styles.css';

interface GamesHeaderProps {
  heading: string;
  gamesLength: number;
  isLoading?: boolean;
}

export function GamesHeader({
  heading,
  gamesLength,
  isLoading = false,
}: GamesHeaderProps) {
  const {search, setSearch} = useSortAndFiltersStore();

  return (
    <div className="games-header">
      <div className="flex items-end h-8 gap-3">
        <h1 className="games-header-heading">{heading}</h1>
        {!isLoading && (
          <span className="flex justify-center items-center px-3 py-1 rounded-lg bg-white text-xs sm:text-sm">
            {gamesLength}
          </span>
        )}
      </div>
      <div className="flex items-baseline w-full gap-2 sm:gap-8 sm:w-auto">
        <div className="grow flex items-center h-10 gap-2.5 px-4 bg-white rounded-full sm:hidden">
          <Icons.Search />
          <input
            type="text"
            placeholder="Search"
            className="dashboard-header-search-input h-10"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
