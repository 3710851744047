import {useDebounce} from '@uidotdev/usehooks';
import {useWeb3React} from '@web3-react/core';
import {useLayoutEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useSortAndFiltersStore} from 'screens/Dashboard';
import {gameService} from 'services/games';
import {GamesHeader} from 'ui-kit/GamesHeader';
import {Icons} from 'ui-kit/Icons';
import {HostedGameCard} from './components/HostedGameCard';
import {useHostedGamesStore} from './store';
import {useDashboardStore} from 'screens/Dashboard/store/store';

export function HostedGamesView() {
  const {isActive, account} = useWeb3React();
  const {games, isLoading, page, hasMore, totalGames, setHasMore, setPage} =
    useHostedGamesStore();
  const {search} = useSortAndFiltersStore();
  const debounceSearch = useDebounce(search, 1000);
  const [isWalletAuthenticated] = useDashboardStore(state => [
    state.isWalletAuthenticated,
  ]);

  useLayoutEffect(() => {
    if (!isActive || !account) return;

    setPage(0);
    gameService.getHostedGames().catch(err => {
      console.log(err);
    });
  }, [isActive, account, setPage, isWalletAuthenticated]);

  const handleNext = () => {
    if (!isActive || !account) return;

    setPage(page + 1);
    gameService
      .getHostedGames({search: debounceSearch, addToExisting: true})
      .then(res => setHasMore(res.length > 0))
      .catch(err => {
        console.warn(err);
      });
  };
  return (
    <>
      <GamesHeader
        heading="Hosted Games"
        gamesLength={isActive ? totalGames : 0}
        isLoading={isLoading || (isActive && !isWalletAuthenticated)}
      />
      <div className="grid grid-cols-1 grid-flow-row auto-rows-fr gap-6 pb-6 min-[1300px]:auto-rows-[11rem]">
        {(isLoading || (isActive && !isWalletAuthenticated)) && (
          <div className="flex items-center justify-center w-full text-[#A8A8A8]">
            <Icons.Spinner className="animate-spin" color="#A8A8A8" />
          </div>
        )}
        {!isLoading && (!isActive || games.length === 0) && (
          <div className="flex items-center justify-center w-full text-[#A8A8A8]">
            No hosted games
          </div>
        )}
        {!isLoading &&
          isActive &&
          isWalletAuthenticated &&
          games.length > 0 && (
            <InfiniteScroll
              next={handleNext}
              hasMore={hasMore}
              scrollableTarget="scroll-container"
              loader={
                <div className="flex items-center justify-center w-full my-8 text-[#A8A8A8]">
                  <Icons.Spinner className="animate-spin" color="#A8A8A8" />
                </div>
              }
              dataLength={games.length}
              className="grid grid-cols-1 auto-rows-fr gap-6 pb-6 min-[1380px]:auto-rows-[11rem]"
            >
              {games.map((game, i) => (
                <HostedGameCard key={i} game={game} />
              ))}
            </InfiniteScroll>
          )}
      </div>
    </>
  );
}
