import clsx from 'clsx';
import {forwardRef} from 'react';
import {ControllerRenderProps} from 'react-hook-form';
import {Icons} from 'ui-kit/Icons';
import {variants} from './variants';

interface FormCheckboxProps extends Omit<ControllerRenderProps, 'ref'> {
  label: string;
  className?: string;
  variant?: keyof typeof variants;
}

export const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps>(
  ({label, className, variant = 'DEFAULT', ...props}, ref) => {
    return (
      <label className={clsx(variants[variant].container, className)}>
        <input type="checkbox" className="hidden" ref={ref} {...props} />
        <span
          className={clsx(
            variants[variant].checkbox,
            !(props.value as boolean) && variants[variant].unchecked,
            (props.value as boolean) && variants[variant].checked
          )}
        >
          {props.value && <Icons.Tick {...variants[variant].icon} />}
        </span>
        {label}
      </label>
    );
  }
);
