import {DialogTrigger} from '@radix-ui/react-dialog';
import {useWeb3React} from '@web3-react/core';
import {AxiosError} from 'axios';
import {errors} from 'ethers';
import {formatEther} from 'ethers/lib/utils';
import {AnimatePresence} from 'framer-motion';
import {useChain} from 'hooks/useChain';
import {TARGET_CHAIN} from 'lib/web3-react/constants/chains';
import {FormEvent, useEffect, useMemo, useState} from 'react';
import {useDashboardStore} from 'screens/Dashboard/store/store';
import {gameService} from 'services/games';
import {EthersError} from 'types';
import {BetStatus, Game} from 'types/Game';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {getBetForWithdraw} from 'utils/game';
import {WithdrawForm} from './WithdrawForm';
import {WithdrawSuccess} from './WithdrawSuccess';
import {Step} from './types';

interface WithdrawDialogProps {
  game: Game;
  onSuccessfulWithdraw?: () => void;
}

export function WithdrawDialog({
  game,
  onSuccessfulWithdraw,
}: WithdrawDialogProps) {
  const {account, provider} = useWeb3React();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<Step>('withdraw');
  const {bets} = game;
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const {chainId, switchChain} = useChain();
  const addToast = useDashboardStore(s => s.addToast);

  const withdrawBet = useMemo(() => {
    return account ? getBetForWithdraw(bets, account) : undefined;
  }, [bets, account]);

  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const hostFee = game.hostFee / 100 || 0;
    const platformFee = game.platformFee / 100 || 0;

    const amount = +formatEther(withdrawBet?.amountForWithdraw || 0);
    setAmount(amount - amount * (hostFee + platformFee));
  }, [withdrawBet?.amountForWithdraw, game.hostFee, game.platformFee]);

  useEffect(() => {
    if (!account) return;

    if (
      withdrawBet?.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE &&
      isOpen
    ) {
      setIsLoading(true);
      const hostFee = game.hostFee / 100 || 0;
      const platformFee = game.platformFee / 100 || 0;
      gameService
        .getWithdrawBet(game.id, withdrawBet.id, account || '', true)
        .then(data => {
          if (data) {
            const amount = +formatEther(data.data.amount || 0);
            setAmount(amount - amount * (hostFee + platformFee));
          }
        })
        .catch(console.warn)
        .finally(() => setIsLoading(false));
    }
  }, [
    account,
    game.hostFee,
    game.id,
    game.platformFee,
    isOpen,
    withdrawBet?.id,
    withdrawBet?.status,
  ]);

  const handleDialogClose = (isOpen: boolean) => {
    setIsOpen(isOpen);
    if (isOpen) return;
    if (step === 'success') onSuccessfulWithdraw?.();
    setStep('withdraw');
    setIsWithdrawing(false);
    setError(null);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsWithdrawing(true);
    setError(null);
    if (!chainId || !withdrawBet || !account) return;

    try {
      if (chainId !== TARGET_CHAIN.chainId) {
        await switchChain(TARGET_CHAIN.chainId);
        return;
      }

      const signer = provider?.getSigner(account);

      if (!signer) {
        throw new Error('No signer');
      }

      await gameService.withdraw(game, withdrawBet, account, signer);

      setIsWithdrawing(false);
      setStep('success');
    } catch (error) {
      const ethersError = error as EthersError;
      if (ethersError.code === errors.ACTION_REJECTED) {
        setError(
          'Transaction Rejected: It appears you have declined the transaction.'
        );
        return;
      }
      if (ethersError.code === errors.NETWORK_ERROR) return;
      if (error instanceof AxiosError) {
        addToast({
          status: 'error',
          children: error.response?.data?.message || error.message,
        });
      } else if ('reason' in ethersError) {
        addToast({
          status: 'error',
          children: ethersError.reason || 'Oops, something went wrong',
        });
      } else if (error instanceof Error) {
        addToast({
          status: 'error',
          children: error.message || 'Oops, something went wrong',
        });
      }
    } finally {
      setIsWithdrawing(false);
    }
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={handleDialogClose}>
      <DialogTrigger asChild>
        <button className="btn-game bg-black">
          {withdrawBet?.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE
            ? 'Withdraw Jackpot'
            : 'Withdraw'}
        </button>
      </DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal
            forceMount
            contentProps={{
              onInteractOutside: e => {
                if (isWithdrawing) e.preventDefault();
              },
            }}
          >
            {step === 'withdraw' && (
              <form action="POST" onSubmit={e => handleSubmit(e).catch}>
                <WithdrawForm
                  isLoading={isLoading}
                  amount={amount}
                  isWithdrawing={isWithdrawing}
                  error={error}
                />
              </form>
            )}
            {step === 'success' && <WithdrawSuccess />}
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
