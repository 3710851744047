import {Web3ReactProvider} from '@web3-react/core';
import {ReactNode, useEffect, useRef} from 'react';
import {PRIORITIZED_CONNECTORS} from '../constants';
import {connectEagerly} from '../connections';
import {Provider} from '../types';

export function Web3ContextProvider({children}: {children: ReactNode}) {
  const tried = useRef(false);

  useEffect(() => {
    const provider = localStorage.getItem('provider');

    if (tried.current || !provider) return;

    const connector = PRIORITIZED_CONNECTORS[provider as Provider].connector;
    connectEagerly(connector).catch(() => {
      console.log('Failed to connect to provider');
    });

    return () => {
      tried.current = true;
    };
  }, []);

  return (
    <Web3ReactProvider
      connectors={Object.values(PRIORITIZED_CONNECTORS).map(connector => [
        connector.connector,
        connector.hooks,
      ])}
    >
      {children}
    </Web3ReactProvider>
  );
}
