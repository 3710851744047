import {initializeConnector} from '@web3-react/core';
import {WalletConnect as WalletConnectV2} from '@web3-react/walletconnect-v2';

import {Connection} from './connections';
import {TARGET_CHAIN} from './constants/chains';

export function buildWalletConnectConnector() {
  const [walletConnectV2, web3WalletConnectHooks] =
    initializeConnector<WalletConnectV2>(
      actions =>
        new WalletConnectV2({
          actions,
          options: {
            projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '',
            chains: [TARGET_CHAIN.chainId],
            showQrModal: true,
          },
        })
    );
  const walletConnectConnection: Connection = {
    connector: walletConnectV2,
    hooks: web3WalletConnectHooks,
    type: 'WalletConnect',
  };
  return walletConnectConnection;
}
