import {GameStatus, UserGameStatus} from 'types/Game';

export function StatusBadge({
  status,
  userGameStatus,
}: {
  status: GameStatus;
  userGameStatus?: UserGameStatus;
}) {
  if (
    (status === GameStatus.IN_PROGRESS ||
      userGameStatus === UserGameStatus.IN_PROGRESS) &&
    userGameStatus !== UserGameStatus.WITHDRAW_AVAILABLE
  ) {
    return (
      <div className="flex items-center h-7 px-3 py-1 rounded-lg text-xs bg-[#FFE7CB] text-[#101520]">
        In progress
      </div>
    );
  }

  if (userGameStatus === UserGameStatus.WITHDRAW_AVAILABLE) {
    return (
      <div className="flex items-center h-7 px-3 py-1 rounded-lg text-xs bg-[#D6EFD4] text-[#578553]">
        Ready for withdrawal
      </div>
    );
  }

  if (userGameStatus === UserGameStatus.FAILED) {
    return (
      <div className="flex items-center h-7 px-3 py-1 rounded-lg text-xs bg-[#FFCBCB] text-[#674141]">
        Failed
      </div>
    );
  }

  return null;
}
