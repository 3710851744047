import {useIsMedium} from 'lib/useBreakpoints';
import {Icons} from './Icons';

export function EthereumValue({value}: {value: number}) {
  const isMd = useIsMedium();

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center justify-center w-4 h-4 rounded-full bg-[#F4F4F4] md:w-6 md:h-6">
        <Icons.Ethereum width={!isMd ? 7 : 10} height={!isMd ? 10 : 16} />
      </div>
      <span className="text-xs leading-normal md:text-sm">{value} ETH</span>
    </div>
  );
}
