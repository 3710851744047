import {SVGAttributes, SVGProps} from 'react';

export const Icons = {
  AllGames: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.8745 16.2494V12.4994C11.8745 12.3336 11.8087 12.1746 11.6915 12.0574C11.5743 11.9402 11.4153 11.8744 11.2495 11.8744H8.74954C8.58378 11.8744 8.42481 11.9402 8.3076 12.0574C8.19039 12.1746 8.12454 12.3336 8.12454 12.4994V16.2494C8.12454 16.4152 8.0587 16.5741 7.94151 16.6913C7.82432 16.8086 7.66537 16.8744 7.49962 16.8744L3.75008 16.8749C3.668 16.8749 3.58671 16.8588 3.51088 16.8274C3.43504 16.796 3.36613 16.7499 3.30809 16.6919C3.25004 16.6339 3.204 16.565 3.17258 16.4891C3.14117 16.4133 3.125 16.332 3.125 16.2499V9.02646C3.125 8.93939 3.14319 8.85327 3.17842 8.77364C3.21364 8.69401 3.26511 8.62261 3.32954 8.56403L9.57911 2.88163C9.69415 2.77703 9.84406 2.71906 9.99955 2.71906C10.155 2.71905 10.3049 2.777 10.42 2.8816L16.6704 8.56403C16.7349 8.62261 16.7863 8.69401 16.8216 8.77364C16.8568 8.85328 16.875 8.9394 16.875 9.02648V16.2499C16.875 16.332 16.8588 16.4133 16.8274 16.4891C16.796 16.565 16.75 16.6339 16.6919 16.6919C16.6339 16.7499 16.565 16.796 16.4891 16.8274C16.4133 16.8588 16.332 16.8749 16.2499 16.8749L12.4995 16.8744C12.3337 16.8744 12.1748 16.8086 12.0576 16.6914C11.9404 16.5741 11.8745 16.4152 11.8745 16.2494V16.2494Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  MyGames: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.875 8.4375H14.375"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 8.4375H8.125"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.875 7.1875V9.6875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4389 4.35468L6.56283 4.375C5.60769 4.37507 4.6831 4.71158 3.95136 5.32546C3.21962 5.93934 2.7275 6.79135 2.56138 7.73194L2.56205 7.73206L1.28358 14.3073C1.20283 14.7654 1.27023 15.2374 1.476 15.6546C1.68177 16.0719 2.0152 16.4126 2.42785 16.6275C2.8405 16.8423 3.31088 16.92 3.77069 16.8492C4.2305 16.7785 4.65578 16.563 4.98477 16.2341L4.98464 16.2339L8.36242 12.5L13.4389 12.4797C14.5164 12.4797 15.5497 12.0517 16.3115 11.2898C17.0734 10.5279 17.5014 9.49462 17.5014 8.41718C17.5014 7.33973 17.0734 6.30642 16.3115 5.54455C15.5497 4.78269 14.5164 4.35468 13.4389 4.35468V4.35468Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4397 7.71173L18.7171 14.3073C18.7979 14.7654 18.7305 15.2374 18.5247 15.6546C18.3189 16.0719 17.9855 16.4127 17.5729 16.6275C17.1602 16.8423 16.6898 16.92 16.23 16.8492C15.7702 16.7785 15.3449 16.563 15.0159 16.2341L15.0161 16.2339L11.6406 12.4869"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  HostedGames: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.25 11.25C7.97589 11.25 9.375 9.85089 9.375 8.125C9.375 6.39911 7.97589 5 6.25 5C4.52411 5 3.125 6.39911 3.125 8.125C3.125 9.85089 4.52411 11.25 6.25 11.25Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 6.25H19.0625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 10H19.0625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 13.75H19.0625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.40753 15C1.68496 13.9266 2.31113 12.9759 3.18765 12.2971C4.06418 11.6183 5.14139 11.25 6.25001 11.25C7.35863 11.25 8.43584 11.6183 9.31236 12.2971C10.1889 12.9759 10.8151 13.9266 11.0925 15"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  FAQ: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0001 17.5C14.1423 17.5 17.5001 14.1421 17.5001 10C17.5001 5.85786 14.1423 2.5 10.0001 2.5C5.85799 2.5 2.50012 5.85786 2.50012 10C2.50012 14.1421 5.85799 17.5 10.0001 17.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15C10.6904 15 11.25 14.4404 11.25 13.75C11.25 13.0596 10.6904 12.5 10 12.5C9.30964 12.5 8.75 13.0596 8.75 13.75C8.75 14.4404 9.30964 15 10 15Z"
        fill="white"
      />
      <path
        d="M10.0001 10.6254C10.4327 10.6254 10.8556 10.4971 11.2154 10.2567C11.5751 10.0163 11.8555 9.67469 12.021 9.27497C12.1866 8.87526 12.2299 8.43543 12.1455 8.01109C12.0611 7.58676 11.8528 7.19698 11.5469 6.89106C11.2409 6.58513 10.8512 6.37679 10.4268 6.29238C10.0025 6.20798 9.56265 6.2513 9.16294 6.41687C8.76323 6.58243 8.42159 6.86281 8.18122 7.22254C7.94086 7.58227 7.81256 8.00521 7.81256 8.43785"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  LogOut: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.5946 6.71875L16.875 10L13.5946 13.2812"
        stroke="white"
        strokeOpacity="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 10H16.8727"
        stroke="white"
        strokeOpacity="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H8.125"
        stroke="white"
        strokeOpacity="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Search: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.24982 12.5C10.1493 12.5 12.4998 10.1495 12.4998 7.25C12.4998 4.35051 10.1493 2 7.24982 2C4.35032 2 1.99982 4.35051 1.99982 7.25C1.99982 10.1495 4.35032 12.5 7.24982 12.5Z"
        stroke="#101520"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9619 10.9625L13.9995 14.0001"
        stroke="#101520"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Plus: ({color = 'white', ...props}: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      {...props}
    >
      <path
        d="M2.6875 7H12.3125"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 2.1875V11.8125"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Coinbase: () => (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon-buy-and-sell 1" clipPath="url(#clip0_768_6407)">
        <path
          id="Vector"
          d="M28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28C21.732 28 28 21.732 28 14Z"
          fill="#0052FF"
        />
        <path
          id="Vector_2"
          d="M14.0039 18.9219C11.2846 18.9219 9.08203 16.7193 9.08203 14C9.08203 11.2807 11.2846 9.07812 14.0039 9.07812C16.4403 9.07812 18.4623 10.8541 18.852 13.1797H23.8108C23.3924 8.12656 19.1637 4.15625 14.0039 4.15625C8.56933 4.15625 4.16016 8.56543 4.16016 14C4.16016 19.4346 8.56933 23.8438 14.0039 23.8438C19.1637 23.8438 23.3924 19.8734 23.8108 14.8203H18.8479C18.4582 17.1459 16.4403 18.9219 14.0039 18.9219Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_768_6407">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  MarkAll: (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1229_1688)">
        <path
          d="M14.6666 7.38674V8.00007C14.6658 9.43769 14.2003 10.8365 13.3395 11.988C12.4787 13.1394 11.2688 13.9817 9.89016 14.3893C8.51154 14.797 7.03809 14.748 5.68957 14.2498C4.34104 13.7516 3.18969 12.8308 2.40723 11.6248C1.62476 10.4188 1.25311 8.99211 1.3477 7.55761C1.44229 6.12312 1.99806 4.75762 2.93211 3.66479C3.86615 2.57195 5.12844 1.81033 6.53071 1.4935C7.93298 1.17668 9.4001 1.32163 10.7133 1.90674"
          stroke="#181720"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6667 2.66675L8 9.34008L6 7.34008"
          stroke="#181720"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1229_1688">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Wallet: (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.0625 16.5C14.7529 16.5 15.3125 15.9404 15.3125 15.25C15.3125 14.5596 14.7529 14 14.0625 14C13.3721 14 12.8125 14.5596 12.8125 15.25C12.8125 15.9404 13.3721 16.5 14.0625 16.5Z"
        fill="black"
      />
      <path
        d="M3.12502 9.3125V19C3.12502 19.3315 3.25672 19.6495 3.49114 19.8839C3.72556 20.1183 4.0435 20.25 4.37502 20.25H16.875C17.0408 20.25 17.1998 20.1842 17.317 20.0669C17.4342 19.9497 17.5 19.7908 17.5 19.625V11.5C17.5 11.3342 17.4342 11.1753 17.317 11.0581C17.1998 10.9408 17.0408 10.875 16.875 10.875H4.72497C3.87773 10.875 3.15614 10.2162 3.12604 9.36948C3.11839 9.15963 3.15311 8.95039 3.22814 8.75426C3.30316 8.55814 3.41695 8.37915 3.5627 8.22798C3.70845 8.07681 3.88318 7.95658 4.07644 7.87445C4.2697 7.79233 4.47754 7.75 4.68752 7.75H15"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 2.08325V7.91659"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0835 5H22.9168"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Copy: (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1536_1539)">
        <path
          d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.3335 10.0002H2.66683C2.31321 10.0002 1.97407 9.85969 1.72402 9.60964C1.47397 9.35959 1.3335 9.02045 1.3335 8.66683V2.66683C1.3335 2.31321 1.47397 1.97407 1.72402 1.72402C1.97407 1.47397 2.31321 1.3335 2.66683 1.3335H8.66683C9.02045 1.3335 9.35959 1.47397 9.60964 1.72402C9.85969 1.97407 10.0002 2.31321 10.0002 2.66683V3.3335"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1536_1539">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Analitycs: (props: SVGProps<SVGSVGElement>) => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.1511 11.0367C15.296 10.1854 16.0424 8.82675 16.0424 7.29343C16.0424 4.71952 13.9486 2.62573 11.3747 2.62573C8.80078 2.62573 6.70699 4.71952 6.70699 7.29343C6.70699 8.82675 7.4534 10.1854 8.59824 11.0367C6.55758 11.7227 5.08203 13.6498 5.08203 15.9183V16.5691C5.08203 17.3407 5.62988 18.0061 6.38492 18.1508C8.02582 18.465 9.70457 18.6237 11.3754 18.6237C13.0461 18.6237 14.7249 18.4643 16.3664 18.1508C17.1208 18.0061 17.6687 17.3407 17.6687 16.5691V15.9183C17.6673 13.6498 16.1911 11.7227 14.1511 11.0367ZM7.90164 7.29343C7.90164 5.37894 9.45953 3.82105 11.374 3.82105C13.2885 3.82105 14.8464 5.37894 14.8464 7.29343C14.8464 9.20792 13.2885 10.7658 11.374 10.7658C9.45953 10.7658 7.90164 9.20858 7.90164 7.29343ZM16.4714 16.5691C16.4714 16.7683 16.3319 16.9403 16.14 16.9768C13.0063 17.5758 9.74109 17.5758 6.60871 16.9768C6.41613 16.9396 6.27668 16.7683 6.27668 16.5691V15.9183C6.27668 13.7362 8.05172 11.9611 10.2332 11.9611H12.5149C14.6963 11.9611 16.4714 13.7362 16.4714 15.9183V16.5691Z" />
      <path d="M18.7175 10.8222C19.5024 10.109 19.9799 9.08172 19.9799 7.98801C19.9799 6.4102 19.0309 5.01633 17.5627 4.43727C17.2572 4.31708 16.9093 4.46649 16.7877 4.77395C16.6662 5.08075 16.817 5.42805 17.1244 5.54891C18.1331 5.94668 18.7839 6.90426 18.7839 7.98735C18.7839 9.06645 18.1079 10.0506 17.1025 10.4377C16.8475 10.536 16.6915 10.7943 16.724 11.0659C16.7559 11.3375 16.9684 11.552 17.2386 11.5879C18.7215 11.7838 20.0729 13.3218 20.0729 14.8139V15.3252C20.0729 15.4229 20.0038 15.5112 19.9095 15.5298C19.792 15.553 19.6698 15.5743 19.5436 15.5962L19.2886 15.6407C18.9639 15.6998 18.7487 16.0106 18.8072 16.3353C18.8596 16.6241 19.1113 16.826 19.3949 16.826C19.4307 16.826 19.4659 16.8227 19.5024 16.8161L19.7455 16.7736C19.8823 16.7497 20.0158 16.7271 20.1419 16.7018C20.794 16.5737 21.2682 15.9946 21.2682 15.3252V14.8139C21.2682 13.1664 20.1884 11.5746 18.7175 10.8222Z" />
      <path d="M3.45906 15.6419L3.20406 15.5974C3.07855 15.5754 2.9557 15.5549 2.83617 15.5303C2.74453 15.5124 2.6748 15.4247 2.6748 15.3264V14.8151C2.6748 13.3229 4.02551 11.785 5.50902 11.5891C5.7793 11.5532 5.99246 11.3387 6.02367 11.0671C6.05621 10.7955 5.89949 10.5372 5.64516 10.4389C4.6391 10.0518 3.96375 9.06697 3.96375 7.98853C3.96375 6.90478 4.61453 5.9472 5.62324 5.55009C5.9307 5.42857 6.08078 5.08126 5.95992 4.77447C5.8384 4.46701 5.48977 4.31693 5.18496 4.43779C3.71672 5.01685 2.76777 6.41072 2.76777 7.98853C2.76777 9.08158 3.2459 10.1095 4.03016 10.8227C2.55926 11.5745 1.47949 13.1662 1.47949 14.8151V15.3264C1.47949 15.9958 1.95363 16.5749 2.60375 16.7024C2.73125 16.7283 2.86539 16.7508 3.00219 16.7747L3.24523 16.8172C3.28176 16.8239 3.31695 16.8272 3.35281 16.8272C3.63637 16.8272 3.88805 16.6247 3.94051 16.3365C3.99961 16.0117 3.78379 15.701 3.45906 15.6419Z" />
    </svg>
  ),
};
