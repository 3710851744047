import {DialogClose} from '@radix-ui/react-dialog';
import clsx from 'clsx';
import {AnimatePresence} from 'framer-motion';
import {useIsMedium} from 'lib/useBreakpoints';
import {Game} from 'types/Game';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {EthereumValue} from 'ui-kit/EthereumValue';
import {Icons} from 'ui-kit/Icons';
import {ProgressBar} from 'ui-kit/ProgressBar';
import {ScrollArea} from 'ui-kit/ScrollArea';
import {formatAddress} from 'utils/web3';
import {useWeb3React} from '@web3-react/core';
import {formatEther} from 'ethers/lib/utils';
import {useMemo} from 'react';
import {getSortedBets} from 'utils/game';

interface GamePlayersDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  game: Game;
}

export function GamePlayersDialog({
  isOpen,
  onOpenChange,
  game,
}: GamePlayersDialogProps) {
  const {account} = useWeb3React();
  const {bets, roi} = game;
  const isMd = useIsMedium();

  const sortedBets = useMemo(() => {
    if (!isOpen) return bets;

    return getSortedBets(bets);
  }, [bets, isOpen]);

  return (
    <DialogRoot open={isOpen} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal forceMount variant={!isMd ? 'mobile' : undefined}>
            <div className="relative flex flex-col w-full md:w-[700px] lg:w-[753px] gap-6 px-5 pt-8 pb-6 md:gap-[3.125rem] md:px-[3.125rem] md:py-[3.75rem]">
              <DialogClose className="absolute top-[22px] right-[17px] flex justify-center items-center w-5 h-5 outline-none md:top-[34px] md:right-[31px]">
                <Icons.Close />
              </DialogClose>
              <h2 className="text-center text-xl leading-9 text-[#101520] md:text-[1.75rem]">
                Game players
              </h2>
              {bets.length === 0 && (
                <p className="text-sm text-center text-[#A0A4AC]">
                  No players yet.
                </p>
              )}
              {bets.length > 0 && (
                <ScrollArea viewPortClassName="py-5 max-h-[calc(100vh-275px)]">
                  <table className="w-full">
                    <thead>
                      <tr className="flex justify-between mb-8 font-medium text-xs sm:text-sm leading-5 text-[#A0A4AC]">
                        <td className="w-[120px] md:w-[200px]">Address</td>
                        <td className="w-[72px] md:w-[100px]">Bet</td>
                        <td className="w-[106px] md:w-[173px]">Progress</td>
                      </tr>
                    </thead>
                    <tbody className="grid gap-8">
                      {sortedBets.map(bet => (
                        <tr
                          key={bet.id}
                          className={clsx(
                            'grid grid-cols-[120px_72px_106px] justify-between items-start text-[#101520] md:grid-cols-[200px_100px_173px] md:items-center',
                            account === bet.user.wallet &&
                              'bg-gray-50 rounded-lg'
                          )}
                        >
                          <td
                            className="font-semibold text-xs md:text-sm leading-5"
                            title={bet.user.wallet}
                          >
                            {formatAddress(bet.user.wallet)}
                          </td>
                          <td>
                            <EthereumValue
                              value={Number(formatEther(bet.amount))}
                            />
                          </td>
                          <td>
                            <ProgressBar
                              current={Math.round(
                                (Number(formatEther(bet.collectedAmount)) /
                                  Number(formatEther(bet.amount))) *
                                  100
                              )}
                              total={roi + 100}
                              variant={isMd ? undefined : 'small'}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ScrollArea>
              )}
            </div>
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
