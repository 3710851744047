import {CoinbaseWallet} from '@web3-react/coinbase-wallet';
import {Web3ReactHooks} from '@web3-react/core';
import {Connector} from '@web3-react/types';
import {TARGET_CHAIN} from './constants/chains';
import {Provider} from './types';
import {getProviderByConnector} from './utils';
import {WalletConnect} from '@web3-react/walletconnect-v2';

export interface Connection {
  connector: Connector;
  hooks: Web3ReactHooks;
  type: Provider;
}

function getIsBraveWallet(): boolean {
  return (window?.ethereum?.isBraveWallet as boolean | undefined) ?? false;
}

export function getHasMetaMaskExtensionInstalled(): boolean {
  return (
    ((window.ethereum?.isMetaMask as boolean | undefined) ?? false) &&
    !getIsBraveWallet()
  );
}

export function onConnectionError(error: Error) {
  console.debug(`web3-react error: ${error.message}`);
}

export const tryActivate = async (connector: Connector): Promise<void> => {
  if (connector instanceof WalletConnect) {
    await connector.activate(TARGET_CHAIN.chainId);
  } else {
    await connector.activate(TARGET_CHAIN);
  }
  const provider = getProviderByConnector(connector);
  localStorage.setItem('provider', provider);
};

export async function connectEagerly(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await tryActivate(connector);
    }
  } catch (error) {
    console.debug(
      `web3-react eager connection error: ${(error as Error).message}`
    );
  }
}

export const tryDeactivate = async (
  connector: Connector
): Promise<null | undefined> => {
  await connector.deactivate?.();
  await connector.resetState();
  localStorage.removeItem('provider');
  localStorage.removeItem('token');
  return null;
};
