import {useWeb3React} from '@web3-react/core';
import {useSignMessage} from 'hooks/useSignMessage';
import {ReactNode, useEffect, useRef} from 'react';
import {authApi} from 'services/auth';
import {tryDeactivate} from '../connections';
import {useDashboardStore} from 'screens/Dashboard/store/store';
import {useLocation} from 'react-router-dom';

export const MESSAGE =
  'Welcome to ponzi.market\nClick to sign in to ponzi.market and accept Terms & Conditions\n\nThis request will not trigger any blockchain transaction or cost any gas fees.';

export function Web3Interceptor({children}: {children: ReactNode}) {
  const {account, connector} = useWeb3React();
  const {signMessage} = useSignMessage();
  const oldAccountRef = useRef<string | undefined>();
  const [setIsWalletAuthenticated] = useDashboardStore(state => [
    state.setIsWalletAuthenticated,
  ]);
  const {pathname} = useLocation();

  const checkIsAuthWallet = async (wallet: string) => {
    try {
      const res = await authApi.refresh();
      if (!res) return false;

      const data = JSON.parse(
        Buffer.from(res.access.split('.')[1], 'base64').toString('utf-8')
      );
      if (data.wallet !== wallet) return false;

      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!account || oldAccountRef.current === account || pathname === '/')
      return;
    oldAccountRef.current = account;

    checkIsAuthWallet(account)
      .then(async isAuthWallet => {
        setIsWalletAuthenticated(isAuthWallet);
        if (!account || isAuthWallet) return;

        const data = await signMessage(MESSAGE);
        await authApi.sendSignature(account, data);
        setIsWalletAuthenticated(true);
      })
      .catch(async () => {
        await tryDeactivate(connector);
        setIsWalletAuthenticated(false);
        oldAccountRef.current = undefined;
      });
  }, [account, connector, pathname]);

  return <>{children}</>;
}
