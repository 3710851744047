import {z} from 'zod';

export const GameDepositScheme = z.object({
  deposit: z.coerce.number({
    invalid_type_error: 'This field is required',
  }),
  isRulesAccepted: z.boolean(),
});

export type GameDepositType = z.infer<typeof GameDepositScheme>;
