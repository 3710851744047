import {
  ToastProps as Props,
  Root as ToastRoot,
  Title as ToastTitle,
} from '@radix-ui/react-toast';
import {AnimatePresence, motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import './styles.css';

export interface ToastProps extends Omit<Props, 'id'> {
  id: string;
  status: 'success' | 'error';
  open?: boolean;
  timeoutMs?: number;
  onOpenChange?: (isOpen: boolean) => void;
  onRemove?: () => void;
}

export function Toast({
  status = 'success',
  children,
  timeoutMs = 5000,
  open = true,
  onOpenChange,
  onRemove,
  ...props
}: ToastProps) {
  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
    }, timeoutMs);

    return () => clearTimeout(timer);
  }, [timeoutMs]);

  return (
    <AnimatePresence
      onExitComplete={() => {
        if (!isOpen) {
          onRemove?.();
        }
      }}
    >
      {isOpen && (
        <ToastRoot
          {...props}
          open={isOpen}
          onOpenChange={() => {
            setIsOpen(!isOpen);
            onOpenChange?.(!isOpen);
          }}
          asChild
        >
          <motion.div
            initial={{opacity: 0, x: 50}}
            animate={{opacity: 1, x: 0}}
            exit={{opacity: 0, x: 50}}
            className={`flex justify-start items-center w-full px-6 py-[18px] rounded-tr-lg rounded-br-lg bg-white text-sm pointer-events-auto sm:w-auto toast-status toast-shadow toast-${status}`}
          >
            <ToastTitle className="[grid-area:_title] mb-[5px] font-medium text-slate12 text-[15px]">
              {children}
            </ToastTitle>
          </motion.div>
        </ToastRoot>
      )}
    </AnimatePresence>
  );
}
