export const handleScrollTo = (el: HTMLElement | null) => {
  if (!el) return;
  el.scrollIntoView({behavior: 'smooth'});
};

export const readImage = (file: File): Promise<string | ArrayBuffer | null> => {
  if (!file.type.includes('image')) {
    return Promise.reject(new Error('Not an image'));
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = error => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const withPrecision = (value: number, precision = 4) => {
  const multiplier = 10 ** precision;

  return Math.floor(value * multiplier) / multiplier;
};
