import {BigNumber, BigNumberish} from 'ethers';

export enum GameStatus {
  AWAITING_DEPOSIT = 'AWAITING_DEPOSIT',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export enum UserGameStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  WITHDRAW_AVAILABLE = 'WITHDRAW_AVAILABLE',
  FINISHED = 'FINISHED',
}

export interface Game {
  id: string;
  name: string;
  thumbnailUrl: string;
  status: GameStatus;
  userGameStatus?: UserGameStatus;
  roi: number;
  minDeposit: number;
  maxDeposit: number;
  hostFee: number;
  platformFee: number;
  softPool: string;
  hardPool: string;
  host: GameHost;
  bets: Bet[];
  membersCount: number;
  hostId: string;
  createdAt: string;
  contractAddress: string;
  finishAt: string;
}

export interface GameHost {
  id: string;
  wallet: string;
  createdAt: string;
}

export enum BetStatus {
  PENDING = 'PENDING',
  WITHDRAW_AVAILABLE = 'WITHDRAW_AVAILABLE',
  JACKPOT_WITHDRAW_AVAILABLE = 'JACKPORT_WITHDRAW_AVAILABLE',
  WITHDRAWED = 'WITHDRAWED',
}

export interface Bet {
  id: string;
  amount: string;
  collectedAmount: string;
  amountForWithdraw: string;
  status: BetStatus;
  gameId: string;
  user: {
    id: string;
    wallet: string;
    createdAt: string;
  };
  createdAt: Date;
}

export interface GameRequestOptions {
  page?: number;
  limit?: number;
  addToExisting?: boolean;
  search?: string;
}

export interface CreateGameParams {
  payableAmount: BigNumberish;
  name: string;
  file: string;
  fee: BigNumber;
  maxDeposit: BigNumber;
  minDeposit: BigNumber;
  roi: BigNumber;
}

export interface GamesResponse {
  total: number;
  data: Game[];
}

export interface WithdrawResponse {
  signarute: string;
  data: {
    amount: string;
    recipient: string;
    nonce: number;
  };
}
