import clsx from 'clsx';
import {ButtonHTMLAttributes, ReactNode} from 'react';

interface WalletButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  leftIcon: ReactNode;
}

export function WalletButton({
  children,
  leftIcon,
  ...props
}: WalletButtonProps) {
  return (
    <button
      className={clsx(
        'flex justify-start items-center gap-3 w-full h-[60px] p-3 pl-4 rounded-xl bg-[#F9F9F9] text-left text-sm outline-none transition-opacity',
        props.disabled ? 'opacity-50' : 'cursor-pointer'
      )}
      {...props}
    >
      {leftIcon && (
        <span className="flex justify-center items-center w-7 h-7 rounded-full bg-white">
          {leftIcon}
        </span>
      )}
      {children}
    </button>
  );
}
