import {Buffer} from 'buffer';
import {Web3Interceptor} from 'lib/web3-react/components/Web3Interceptor';
import {Web3ContextProvider} from 'lib/web3-react/components/Web3React';
import {useEffect} from 'react';
import {createHashRouter, RouterProvider} from 'react-router-dom';
import {Price} from 'services/price';

import {AllGamesView} from 'screens/AllGames';
import {DashboardView} from 'screens/Dashboard';
import {FAQView} from 'screens/FAQ';
import {HostedGamesView} from 'screens/HostedGames';
import {LandingView} from 'screens/Landing';
import {MyGamesView} from 'screens/MyGames';
import {TermsAndConditionsView} from 'screens/TermAndConditions';

const router = createHashRouter([
  {
    path: '/',
    element: <LandingView />,
  },
  {
    path: '/',
    element: (
      <Web3Interceptor>
        <DashboardView />
      </Web3Interceptor>
    ),
    children: [
      {
        path: '/dashboard',
        element: <AllGamesView />,
      },
      {
        path: '/dashboard/my',
        element: <MyGamesView />,
      },
      {
        path: '/dashboard/hosted',
        element: <HostedGamesView />,
      },
      {
        path: '/faq',
        element: <FAQView />,
      },
      {
        path: '/terms',
        element: <TermsAndConditionsView />,
      },
    ],
  },
]);

window.Buffer = Buffer;

export function App() {
  useEffect(() => {
    Price.fetchPrice().catch(console.warn);
  }, []);

  return (
    <>
      <Web3ContextProvider>
        <RouterProvider router={router} />
      </Web3ContextProvider>
    </>
  );
}
