const Step1Img = require('../../assets/Step1.jpg') as string;
const Step2Img = require('../../assets/Step2.jpg') as string;
const Step3Img = require('../../assets/Step3.jpg') as string;
const Step4Img = require('../../assets/Step4.jpg') as string;
const Step5Img = require('../../assets/Step5.jpg') as string;
const Step6Img = require('../../assets/Step6.jpg') as string;
const Step7Img = require('../../assets/Step7.jpg') as string;
const Step8Img = require('../../assets/Step8.jpg') as string;
const hostlandImg = require('../../assets/hostland.jpg') as string;
const roiland2Img = require('../../assets/roiland2.jpg') as string;
const jacklandImg = require('../../assets/jackland.jpg') as string;

export const steps = [
  {
    heading: (
      <>
        Be early
        <br />
        and get fast ROI
      </>
    ),
    description:
      'Deposit into a game and get up to 100% ROI paid by those who join after you. You will be able to withdraw once your ROI is fulfilled.',
    imageSrc: roiland2Img,
  },
  {
    heading: (
      <>
        Be the very last
        <br />
        and get massive jackpot
      </>
    ),
    description:
      'Bonus payout! All of the midwit deposits that never got ROI are pooled and won by the very last person to join. Each new deposit resets the game timer though.',
    imageSrc: jacklandImg,
  },
  {
    heading: (
      <>
        Be the host
        <br />
        and rack in 1-5% fees
      </>
    ),
    description:
      'Sell shovels during the gold rush! Host your own game and get fees from every deposit.',
    imageSrc: hostlandImg,
  },
  {
    heading: (
      <>
        Be the midwit
        <br />
        and lose it all
      </>
    ),
    description:
      'You either want to be first or the very last. Mediocrity kills here. If not enough players join after you - you will lose your deposit. ',
    imageSrc: Step6Img,
  },
];
