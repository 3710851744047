import clsx from 'clsx';
import {InputHTMLAttributes, ReactNode, forwardRef} from 'react';

interface FormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export const FormField = forwardRef<HTMLInputElement, FormFieldProps>(
  ({label, leftIcon, rightIcon, ...props}, ref) => {
    return (
      <label className="flex flex-col gap-2">
        <span className="text-sm">{label}</span>
        <span
          className={clsx(
            'flex items-center gap-2.5 px-5 bg-[#F9F9F9] rounded-xl',
            (props.readOnly || props.disabled) && 'cursor-default',
            (!props.readOnly || !props.disabled) && 'cursor-text'
          )}
        >
          {leftIcon}
          <input
            ref={ref}
            type="text"
            className="input-number w-full grow py-3.5 bg-transparent text-sm text-[#101520] outline-none placeholder:text-[#7C7C7C] read-only:cursor-default sm:w-auto"
            {...props}
          />
          {rightIcon}
        </span>
      </label>
    );
  }
);
