import {DialogTitle} from '@radix-ui/react-dialog';
import {
  getHasMetaMaskExtensionInstalled,
  tryActivate,
} from 'lib/web3-react/connections';
import {PRIORITIZED_CONNECTORS} from 'lib/web3-react/constants';
import {Provider} from 'lib/web3-react/types';
import {useState} from 'react';
import {useDashboardStore} from 'screens/Dashboard/store/store';
import {WalletButton} from './WalletButton';

const metamaskIcon =
  require('../../../../assets/wallets/metaMask.png') as string;
const walletConnectIcon =
  require('../../../../assets/wallets/walletConnect.png') as string;
const coinbaseIcon =
  require('../../../../assets/wallets/coinbase.png') as string;

interface ConnectWalletDialogContentProps {
  onWalletConnect: () => void;
  onLockChange?: (isLocked: boolean) => void;
}

export function ConnectWalletDialogContent({
  onWalletConnect,
  onLockChange,
}: ConnectWalletDialogContentProps) {
  const [isConnecting, setIsConnecting] = useState(false);
  const addToast = useDashboardStore(state => state.addToast);

  const isMetaMaskInstalled = getHasMetaMaskExtensionInstalled();

  const handleWalletConnect = (
    provider: 'MetaMask' | 'WalletConnect' | 'Coinbase'
  ) => {
    if (provider === 'MetaMask' && !isMetaMaskInstalled) {
      window.open(
        `https://metamask.app.link/dapp/${window.location.href}`,
        '_self'
      );
    }

    setIsConnecting(true);
    onLockChange?.(true);
    tryActivate(PRIORITIZED_CONNECTORS[provider as Provider].connector)
      .then(() => {
        addToast({
          status: 'success',
          children: 'Your wallet has been connected',
        });

        onWalletConnect();
      })
      .catch(() => {
        console.warn(`Failed to connect to ${provider}`);
      })
      .finally(() => {
        setIsConnecting(false);
        onLockChange?.(false);
      });
  };

  return (
    <div className="flex flex-col items-center w-[295px] sm:w-[543px] gap-[50px] p-5 pt-10 sm:p-[60px] sm:pb-[50px]">
      <DialogTitle className="dialog-title sm:leading-9">
        Connect your wallet
      </DialogTitle>
      <div className="flex flex-col items-center gap-4 w-full">
        <WalletButton
          disabled={isConnecting}
          leftIcon={
            <img
              src={metamaskIcon}
              alt="Metamask"
              className="w-[22px] h-[22px]"
            />
          }
          onClick={() => handleWalletConnect('MetaMask')}
        >
          Metamask
        </WalletButton>
        <WalletButton
          disabled={isConnecting}
          leftIcon={
            <img
              src={walletConnectIcon}
              alt="WalletConnect"
              className="w-[22px] h-[22px]"
            />
          }
          onClick={() => handleWalletConnect('WalletConnect')}
        >
          WalletConnect
        </WalletButton>
        <WalletButton
          disabled={isConnecting}
          leftIcon={
            <img
              src={coinbaseIcon}
              alt="Coinbase"
              className="w-[22px] h-[22px]"
            />
          }
          onClick={() => handleWalletConnect('Coinbase')}
        >
          Coinbase
        </WalletButton>
      </div>
    </div>
  );
}
