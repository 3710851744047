import {Game} from 'types/Game';
import {create} from 'zustand';

interface HostedGamesStore {
  games: Game[];
  isLoading: boolean;
  page: number;
  hasMore: boolean;
  totalGames: number;
  setGames: (games: Game[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setPage: (page: number) => void;
  setHasMore: (hasMore: boolean) => void;
  setGame: (game: Game) => void;
}

export const hostedGamesStore = create<HostedGamesStore>((set, get) => ({
  games: [],
  isLoading: false,
  page: 0,
  hasMore: true,
  totalGames: 0,
  setGames: games => set({games, isLoading: false}),
  setIsLoading: isLoading => set({isLoading}),
  setPage: page => set({page}),
  setHasMore: hasMore => set({hasMore}),
  setGame: game => {
    const games = get().games;
    const gameIndex = games.findIndex(task => task.id === game.id);
    games[gameIndex] = {
      ...games[gameIndex],
      ...game,
    };
    set({games});
  },
}));

export const useHostedGamesStore = hostedGamesStore;
