import {DialogTrigger} from '@radix-ui/react-dialog';
import {AnimatePresence} from 'framer-motion';
import {ReactNode, useEffect, useState} from 'react';
import {analyticService} from 'screens/Dashboard/service/analyticsService';
import {useAnalyticStore} from 'screens/Dashboard/store/analytic';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {Icons} from 'ui-kit/Icons';

interface AnalyticsDialogProps {
  children: ReactNode;
}

export function AnalyticsDialog({children}: AnalyticsDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const {isAnalyticLoading, totalFees, averageFeePerUser} = useAnalyticStore();

  const handleDialogClose = (isOpen: boolean) => {
    setIsOpen(isOpen);
    if (isOpen) return;
  };

  useEffect(() => {
    void analyticService.getAnalytics();
  }, []);

  return (
    <DialogRoot open={isOpen} onOpenChange={handleDialogClose}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <>
            <DialogPortal forceMount>
              <div className="w-[300px] h-fit py-4 bg-white">
                {isAnalyticLoading && (
                  <div className="flex flex-col items-center justify-center">
                    <Icons.Spinner className="animate-spin" color="#A8A8A8" />
                  </div>
                )}

                {!isAnalyticLoading && (
                  <div className="flex flex-col items-center p-4 gap-6 h-full w-full">
                    <h2 className="text-xl font-bold">Anatytics</h2>

                    <div className="flex flex-col gap-8 w-full h-fit text-center">
                      <div className="flex flex-col gap-2 capitalize">
                        <p>Total fees:</p>
                        <p className="opacity-60">{totalFees}</p>
                      </div>
                      <div className="flex flex-col gap-2 capitalize">
                        <p>Average fee per user:</p>
                        <p className="opacity-60">{averageFeePerUser}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </DialogPortal>
          </>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
