import {useEffect} from 'react';
import {STAGE} from '../../constants';
import './LandingView.css';
import {LandingFooter} from './components/LandingFooter';
import {LandingGames} from './components/LandingGames';
import {LandingHeader} from './components/LandingHeader';
import {LandingHero} from './components/LandingHero';
import {LandingInstructions} from './components/LandingInstructions';

export function LandingView() {
  useEffect(() => {
    // TODO: This is only for HashRouter
    if (window.location.href.endsWith('how-it-works')) {
      document.getElementById('how-it-works')?.scrollIntoView();
    }
  }, []);

  return (
    <div className="bg-[#f3f3f3] font-body">
      <LandingHeader />
      <main>
        <LandingHero />
        <LandingInstructions />
        <LandingGames />
      </main>
      <LandingFooter />
    </div>
  );
}
