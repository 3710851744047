import {
  PopoverContent,
  PopoverContentProps,
  PopoverPortal,
  PopoverProps,
  Root as PopoverRoot,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';
import {useIsSmall} from 'lib/useBreakpoints';
import {ReactNode, useEffect, useState} from 'react';

interface PopoverExtendedProps extends PopoverProps {
  children: ReactNode;
  trigger: ReactNode;
  popoverContent?: PopoverContentProps;
  classNames?: {
    content?: string;
  };
}

export function Popover({
  children,
  trigger,
  popoverContent,
  classNames,
  ...props
}: PopoverExtendedProps) {
  const [isOpen, setIsOpen] = useState(
    props.defaultOpen ?? props.open ?? false
  );
  const isSmall = useIsSmall();

  useEffect(() => {
    if (props.open === undefined) return;
    setIsOpen(props.open);
  }, [props.open]);

  function handleOpenChange(open: boolean) {
    setIsOpen(open);
    props.onOpenChange?.(open);
  }

  return (
    <PopoverRoot open={isOpen} onOpenChange={handleOpenChange} {...props}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <AnimatePresence>
        {isOpen && (
          <PopoverPortal forceMount>
            <>
              {props.modal && (
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  className="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-40"
                />
              )}
              <PopoverContent
                sideOffset={!isSmall ? 18 : 22}
                collisionPadding={!isSmall ? 20 : 39}
                {...popoverContent}
              >
                <motion.div
                  initial={{
                    scale: 0.5,
                    opacity: 0,
                  }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                  }}
                  exit={{
                    scale: 0.5,
                    opacity: 0,
                  }}
                  className={clsx(
                    'border border-[#EAECF5] bg-white rounded-2xl',
                    classNames?.content
                  )}
                >
                  {children}
                </motion.div>
              </PopoverContent>
            </>
          </PopoverPortal>
        )}
      </AnimatePresence>
    </PopoverRoot>
  );
}
