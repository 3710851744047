export const gameABI = [
  {inputs: [], stateMutability: 'nonpayable', type: 'constructor'},
  {inputs: [], name: 'InvalidShortString', type: 'error'},
  {
    inputs: [{internalType: 'string', name: 'str', type: 'string'}],
    name: 'StringTooLong',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {indexed: false, internalType: 'uint256', name: 'value', type: 'uint256'},
    ],
    name: 'Deposit',
    type: 'event',
  },
  {anonymous: false, inputs: [], name: 'EIP712DomainChanged', type: 'event'},
  {
    anonymous: false,
    inputs: [
      {indexed: false, internalType: 'uint8', name: 'version', type: 'uint8'},
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {indexed: false, internalType: 'uint256', name: 'value', type: 'uint256'},
      {indexed: false, internalType: 'string', name: 'betId', type: 'string'},
    ],
    name: 'JackpotWithdraw',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: false, internalType: 'bytes32', name: 'reqId', type: 'bytes32'},
      {indexed: false, internalType: 'uint256', name: 'value', type: 'uint256'},
    ],
    name: 'RequestFulfilled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: false, internalType: 'bytes32', name: 'reqId', type: 'bytes32'},
      {indexed: false, internalType: 'uint256', name: 'value', type: 'uint256'},
    ],
    name: 'RequestRejected',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'gameId',
        type: 'uint256',
      },
      {indexed: false, internalType: 'uint256', name: 'value', type: 'uint256'},
      {indexed: false, internalType: 'string', name: 'betId', type: 'string'},
    ],
    name: 'Withdraw',
    type: 'event',
  },
  {
    inputs: [],
    name: 'MAX_BPS',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'creator',
    outputs: [{internalType: 'address', name: '', type: 'address'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'creatorFee',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'deposit',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'eip712Domain',
    outputs: [
      {internalType: 'bytes1', name: 'fields', type: 'bytes1'},
      {internalType: 'string', name: 'name', type: 'string'},
      {internalType: 'string', name: 'version', type: 'string'},
      {internalType: 'uint256', name: 'chainId', type: 'uint256'},
      {internalType: 'address', name: 'verifyingContract', type: 'address'},
      {internalType: 'bytes32', name: 'salt', type: 'bytes32'},
      {internalType: 'uint256[]', name: 'extensions', type: 'uint256[]'},
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'factory',
    outputs: [
      {internalType: 'contract IGameFactory', name: '', type: 'address'},
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'id',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'uint256', name: '_id', type: 'uint256'},
      {internalType: 'address', name: '_creator', type: 'address'},
      {internalType: 'uint256', name: '_creatorFee', type: 'uint256'},
      {internalType: 'uint256', name: '_minDeposit', type: 'uint256'},
      {internalType: 'uint256', name: '_maxDeposit', type: 'uint256'},
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxDeposit',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'minDeposit',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nonce',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{internalType: 'address', name: '', type: 'address'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'platformFee',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: 'newOwner', type: 'address'}],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bool', name: 'isJackpot', type: 'bool'},
      {internalType: 'string', name: 'betId', type: 'string'},
      {internalType: 'uint256', name: 'amount', type: 'uint256'},
      {internalType: 'uint256', name: '_nonce', type: 'uint256'},
      {internalType: 'bytes', name: 'signature', type: 'bytes'},
    ],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {stateMutability: 'payable', type: 'receive'},
];
