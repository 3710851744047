import {useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Icons} from 'ui-kit/Icons';
import {handleScrollTo} from 'utils';
import './styles.css';

export function LandingHeader() {
  const instructionsRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    instructionsRef.current = document.getElementById('how-it-works');
  }, []);

  return (
    <header className="w-full p-5 header">
      <div className="header-nav">
        <Link
          to="/"
          className="flex items-center gap-1.5 font-semibold text-lg leading-none text-white"
        >
          <Icons.Logo />
          ponzi.market
        </Link>
        <div className="header-nav-items">
          <button
            className="opacity-60"
            onClick={() => handleScrollTo(instructionsRef.current)}
          >
            How it works
          </button>
        </div>
      </div>
    </header>
  );
}
