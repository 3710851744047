import {
  Content as TooltipContent,
  TooltipContentProps,
  Portal as TooltipPortal,
  TooltipProvider,
  TooltipProviderProps,
  Root as TooltipRoot,
  Trigger as TooltipTrigger,
} from '@radix-ui/react-tooltip';
import {AnimatePresence, motion} from 'framer-motion';
import {ReactNode, useState} from 'react';
import './styles.css';

interface TooltipProps extends TooltipProviderProps {
  content: ReactNode;
  contentProps?: TooltipContentProps;
}

export function Tooltip({
  content,
  children,
  contentProps,
  ...props
}: TooltipProps) {
  const [isOpen, setOpen] = useState(false);

  return (
    <TooltipProvider {...props}>
      <TooltipRoot open={isOpen} onOpenChange={val => setOpen(val)}>
        <TooltipTrigger>{children}</TooltipTrigger>
        <AnimatePresence>
          {isOpen && (
            <TooltipPortal forceMount>
              <TooltipContent asChild sideOffset={8} {...contentProps}>
                <motion.div
                  initial={{
                    opacity: 0,
                    scale: 0.8,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  exit={{
                    opacity: 0,
                    scale: 0.8,
                  }}
                  className="tooltip"
                >
                  {content}
                </motion.div>
              </TooltipContent>
            </TooltipPortal>
          )}
        </AnimatePresence>
      </TooltipRoot>
    </TooltipProvider>
  );
}
