import {useWeb3React} from '@web3-react/core';
import {useConnectedWalletIcon} from 'hooks/useConnectedWalletIcon';
import {useCopyToClipboard} from 'hooks/useCopyToClipboard';
import {ReactNode, useMemo, useState} from 'react';
import {Icons as GlobalIcons} from 'ui-kit/Icons';
import {Popover} from 'ui-kit/Popover';
import {formatAddress} from 'utils/web3';
import {ConnectWalletDialog} from '../ConnectWalletDialog';
import {DisconnectWalletDialog} from '../DisconnectWalletDialog';
import {Icons} from '../Icons';

interface WalletSettingsPopoverProps {
  children: ReactNode;
  onDisconnected?: () => void; // for testing until we have web3-react
}

export function WalletSettingsPopover({
  children,
  onDisconnected,
}: WalletSettingsPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isConnectWalletOpen, setIsConnectWalletOpen] = useState(false);
  const [isDisconnectWalletOpen, setIsDisconnectWalletOpen] = useState(false);
  const wallet = useConnectedWalletIcon();
  const {account} = useWeb3React();

  const address = useMemo(() => {
    if (!account) return '';

    return formatAddress(account, 6, 4);
  }, [account]);

  const [copiedText, copyToClipboard] = useCopyToClipboard(3000);

  const handleCopyAddress = () => {
    if (copiedText || !account) return;

    copyToClipboard(account);
  };

  const handleSwitchAddress = () => {
    setIsOpen(false);
    setIsConnectWalletOpen(true);
  };

  return (
    <>
      <Popover
        trigger={children}
        open={isOpen}
        onOpenChange={val => setIsOpen(val)}
        classNames={{
          content: 'border-none shadow-[0_4px_20px_0_rgba(0,0,0,0.10)]',
        }}
      >
        <div
          className="flex flex-col items-center gap-8 min-w-[250px] max-w-[334px] p-5 pb-8"
          style={{width: 'var(--radix-popover-content-available-width)'}}
        >
          <div className="flex items-center w-full px-4 py-2.5 rounded-xl bg-[#F3F3F3] font-medium text-[#101520]">
            <img
              src={wallet}
              className="w-8 h-8 mr-2 rounded-full"
              alt="Wallet"
            />
            {formatAddress(address, 6, 4)}
            <button className="ml-auto" onClick={handleCopyAddress}>
              {copiedText ? <GlobalIcons.Tick color="black" /> : <Icons.Copy />}
            </button>
          </div>
          <div className="flex flex-col items-center gap-4">
            <button className="btn" onClick={handleSwitchAddress}>
              Change wallet
            </button>
            <button
              className="font-medium text-sm leading-5 text-[#DB3C48]"
              onClick={() => setIsDisconnectWalletOpen(true)}
            >
              Disconnect wallet
            </button>
          </div>
        </div>
      </Popover>
      <DisconnectWalletDialog
        isOpen={isDisconnectWalletOpen}
        onOpenChange={val => setIsDisconnectWalletOpen(val)}
        onSwitchAddress={handleSwitchAddress}
        onDisconnected={() => {
          setIsOpen(false);
          onDisconnected?.();
        }}
      />
      <ConnectWalletDialog
        isOpen={isConnectWalletOpen}
        onOpenChange={val => setIsConnectWalletOpen(val)}
      />
    </>
  );
}
