import {
  ScrollAreaProps,
  Root as ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@radix-ui/react-scroll-area';
import clsx from 'clsx';
import {ReactNode} from 'react';

interface ScrollAreaExtendedProps extends ScrollAreaProps {
  children?: ReactNode;
  viewPortClassName?: string;
}

export function ScrollArea({
  children,
  className,
  viewPortClassName,
  ...props
}: ScrollAreaExtendedProps) {
  return (
    <ScrollAreaRoot
      type="auto"
      className={clsx('overflow-hidden', className)}
      {...props}
    >
      <ScrollAreaViewport
        className={clsx(
          'w-[calc(100%-21px)] h-full overflow-auto',
          viewPortClassName
        )}
      >
        {children}
      </ScrollAreaViewport>
      <ScrollAreaScrollbar
        className="w-1 mr-[9px] rounded-lg bg-[#EAECF5] bg-opacity-60"
        orientation="vertical"
      >
        <ScrollAreaThumb className="w-1 rounded-lg bg-[#D2D6E4] cursor-pointer" />
      </ScrollAreaScrollbar>
    </ScrollAreaRoot>
  );
}
