import {ToastProvider, ToastViewport} from '@radix-ui/react-toast';
import {Outlet} from 'react-router-dom';
import {Toast} from 'ui-kit/Toast';
import {DashboardAside} from './components/DashboardAside';
import {DashboardHeader} from './components/DashboardHeader/';
import {DashboardMobileNav} from './components/DashboardMobileNav';
import {useDashboardStore} from './store/store';

export function DashboardView() {
  const [toasts, removeToast] = useDashboardStore(state => [
    state.toasts,
    state.removeToast,
  ]);

  return (
    <ToastProvider>
      <div className="h-screen flex bg-[#f3f3f3] font-body">
        <DashboardAside />
        <div
          className="w-full overflow-pseudo-hidden pb-[5rem] md:pb-0"
          id="scroll-container"
        >
          <DashboardHeader />
          <main className="px-5 md:px-10">
            <Outlet />
          </main>
        </div>
        <DashboardMobileNav />
      </div>
      {toasts.map(({children, status, id, ...props}) => (
        <Toast
          key={id}
          id={id}
          status={status}
          onRemove={() => removeToast(id)}
          {...props}
        >
          {children}
        </Toast>
      ))}
      <ToastViewport className="fixed top-[4.25rem] left-5 right-5 sm:top-24 sm:left-10 sm:right-10 bottom-6 flex flex-col gap-4 items-end justify-start pointer-events-none z-[100]" />
    </ToastProvider>
  );
}
