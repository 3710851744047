import './styles.css';

/**
 * Code source: https://goerli.etherscan.io/address/0x89388eF8A5160443dAa089f879C6e50BE192655D#code#F8#L1
 */
export function LandingCode() {
  return (
    <div className="landing-code">
      <pre className="landing-code-container">
        <span className="code-key-word">contract</span>{' '}
        <span className="code-constant">GameFactory</span>{' '}
        <span className="code-key-word">is</span>{' '}
        <span className="code-constant">Ownable</span> &#123;
        <br />
        <br />
        <span>
          &ensp;&ensp;<span className="code-key-word">address</span>{' '}
          <span className="code-key-word">public</span>{' '}
          <span className="code-variable">master</span>;
          <br />
          &ensp;&ensp;<span className="code-key-word">uint256 public</span>{' '}
          <span className="code-variable">gamesCount</span>;
          <br />
          <br />
        </span>
        <span>
          &ensp;&ensp;<span className="code-key-word">mapping</span>(
          <span className="code-variable">address</span> =&gt;{' '}
          <span className="code-key-word">bool</span>){' '}
          <span className="code-key-word">public</span>{' '}
          <span className="code-variable">isGame</span>;
          <br />
          <br />
        </span>
        <span>
          &ensp;&ensp;
          <span className="code-key-word">using</span>{' '}
          <span className="code-constant">Clones</span>{' '}
          <span className="code-key-word">for</span>{' '}
          <span className="code-variable">address</span>;
          <br />
          <br />
        </span>
        <span>
          &ensp;&ensp;<span className="code-key-word">event</span>{' '}
          <span className="code-function">CreateGame</span>&#40;
          <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">address</span>{' '}
          <span className="code-variable">contractAddress</span>, <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">uint256</span>{' '}
          <span className="code-variable">id</span>, <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">uint256</span>{' '}
          <span className="code-variable">initialDeposit</span>, <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">address</span>{' '}
          <span className="code-variable">creator</span>, <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">uint256</span>{' '}
          <span className="code-variable">fee</span>, <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">string</span>{' '}
          <span className="code-variable">name</span>, <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">uint256</span>{' '}
          <span className="code-variable">maxDeposit</span>, <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">uint256</span>{' '}
          <span className="code-variable">minDeposit</span>, <br />
          &ensp;&ensp;&ensp;&ensp;
          <span className="code-key-word">string</span>{' '}
          <span className="code-variable">file</span>
          <br />
          &ensp;&ensp; &#41;
          <br />
          <br />
        </span>
        &#125;
      </pre>
      <div className="landing-code-badge">✅ Open source</div>
    </div>
  );
}
