import {DialogClose, DialogTitle} from '@radix-ui/react-dialog';
import {useFormContext} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {Icons} from 'ui-kit/Icons';

const SuccessImg = require('./assets/success.png') as string;

export function NewGameSuccess() {
  const formCtx = useFormContext();

  return (
    <div className="flex flex-col items-center gap-6 px-5 py-10 sm:px-[100px] sm:py-[60px]">
      <img
        src={SuccessImg}
        alt=""
        className="w-[143px] h-[76px] sm:w-[237px] sm:h-[126px]"
      />
      <div className="flex flex-col gap-8 text-center">
        <DialogTitle className="dialog-title sm:text-[28px] sm:leading-[36px]">
          Congrats! Your game has
          <br />
          been successfully created
        </DialogTitle>
        <div className="dialog-text max-w-[369px]">
          Your game is now active and ready for other users to join. Now go spread the word!
        </div>
        <div className="flex flex-col justify-center items-center gap-6">
          <DialogClose asChild>
            <Link to="/dashboard/hosted" className="btn w-[266px]">
              See hosted games
            </Link>
          </DialogClose>
          <button
            className="flex items-center gap-4 text-sm"
            onClick={() => formCtx.reset()}
          >
            <Icons.Plus color="black" />
            Create another game
          </button>
        </div>
      </div>
    </div>
  );
}
