import axios, {AxiosInstance, InternalAxiosRequestConfig} from 'axios';
import {API_BASE_URL} from '../constants';
import {AuthRefreshResponse, AuthResponse} from 'types/Auth';

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  return config;
};

export class AuthApi {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_BASE_URL,
      headers: {'Content-Type': 'application/json'},
      withCredentials: true,
    });

    this._api.defaults.headers.put['Content-Type'] = 'application/json';
    this._api.interceptors.request.use((config: InternalAxiosRequestConfig) =>
      requestInterceptor(config)
    );
  }

  async sendSignature(
    wallet: string,
    signature: string
  ): Promise<AuthResponse | null> {
    try {
      const url = '/api/auth';
      const res = await this._api.post<AuthResponse>(url, {
        wallet,
        signature,
      });

      localStorage.setItem('token', res.data.tokens.access);

      return res.data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async refresh(isSafe = false): Promise<AuthRefreshResponse | null> {
    try {
      const url = '/api/auth/refresh';
      const res = await this._api.post<AuthRefreshResponse>(url);

      localStorage.setItem('token', res.data.access);

      return res.data;
    } catch (error) {
      localStorage.removeItem('token');
      console.warn(error);
      if (!isSafe) {
        throw error;
      }
    }
    return null;
  }
}

export const authApi = new AuthApi();
