import {create, useStore} from 'zustand';

export interface IAnalytic {
  isAnalyticLoading: boolean;
  totalFees: number;
  averageFeePerUser: number;
}

export const analyticStore = create<IAnalytic>(() => ({
  isAnalyticLoading: true,
  totalFees: 0,
  averageFeePerUser: 0,
}));

export const useAnalyticStore = () => useStore(analyticStore);
