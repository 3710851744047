import {useWeb3React} from '@web3-react/core';
import {ethers} from 'ethers';
import {parseEther, parseUnits} from 'ethers/lib/utils';
import {useChain} from 'hooks/useChain';
import {TARGET_CHAIN} from 'lib/web3-react/constants/chains';
import {useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {gameService} from 'services/games';
import {EthersError} from 'types';
import {FieldEthereumIcon} from 'ui-kit/FieldEthereumIcon';
import {FormField} from 'ui-kit/FormField';
import {FormMessage} from 'ui-kit/FormMessage';
import {Icons} from 'ui-kit/Icons';
import {NewGameStep} from './NewGameStep1';
import {NewGameFormScheme, NewGameFormType, refiners} from './scheme';

export function NewGameStep4() {
  const {isActive, provider, account} = useWeb3React();
  const formCtx = useFormContext<NewGameFormType>();
  const {minDeposit, maxDeposit} = formCtx.getValues();
  const firstDeposit = formCtx.watch('firstDeposit');
  const isLoading = formCtx.watch('isLoading');

  const isInvalid = useMemo(
    () =>
      NewGameFormScheme.pick({
        firstDeposit: true,
        minDeposit: true,
        maxDeposit: true,
      })
        .refine(refiners.firstDeposit)
        .safeParse({
          firstDeposit,
          minDeposit,
          maxDeposit,
        }).success === false,
    [firstDeposit, minDeposit, maxDeposit]
  );

  const {chainId, switchChain} = useChain();

  const handlePrev = () => {
    formCtx.resetField('firstDeposit');
    formCtx.setValue('step', 3);
    formCtx.clearErrors('root');
  };

  const handleNext = async () => {
    try {
      if (!account || !provider) return;
      formCtx.setValue('isLoading', true);
      formCtx.clearErrors('root');
      if (chainId !== TARGET_CHAIN.chainId) {
        await switchChain(TARGET_CHAIN.chainId);
      }

      const {thumbnail, firstDeposit, fee, minDeposit, maxDeposit, roi, name} =
        formCtx.getValues();

      const signer = provider?.getSigner(account);

      if (!signer) {
        throw new Error('No signer');
      }

      const balance = await provider?.getBalance(account);

      if (!balance) {
        throw new Error('No balance');
      }

      const deposit = parseEther(firstDeposit.toString());

      if (balance.lt(deposit)) {
        formCtx.setError('firstDeposit', {
          type: 'custom',
          message: 'Insufficient balance',
        });
        throw new Error('Insufficient balance');
      }

      let thumbnailUrl = '';
      if (thumbnail) {
        const res = await gameService.uploadFile(thumbnail);
        if (res) thumbnailUrl = res;
      }

      await gameService.createGame(signer, {
        payableAmount: deposit,
        name,
        file: thumbnailUrl,
        fee: parseUnits(fee.toString(), 2),
        minDeposit: parseEther(minDeposit.toString()),
        maxDeposit: parseEther(maxDeposit.toString()),
        roi: parseUnits(roi.toString(), 2),
      });

      formCtx.setValue('step', 5);
    } catch (error) {
      if ((error as EthersError).code === ethers.errors.ACTION_REJECTED) {
        formCtx.setError('root', {
          type: 'custom',
          message:
            'Transaction Rejected: It appears you have declined the transaction.',
        });
        return;
      }

      console.warn(error);
    } finally {
      formCtx.setValue('isLoading', false);
    }
  };

  return (
    <NewGameStep step={4} steps={4} title="Add the initial deposit">
      <div className="flex flex-col gap-4 w-[calc(100vw-5rem)] sm:w-[488px]">
        <div className="grid gap-2">
          <FormField
            type="number"
            label="First deposit"
            placeholder={`${minDeposit} ETH`}
            leftIcon={<FieldEthereumIcon />}
            rightIcon={
              <span className="text-xs text-[#A8A8A8]">
                Max.&nbsp;{maxDeposit}&nbsp;ETH
              </span>
            }
            {...formCtx.register('firstDeposit', {
              valueAsNumber: true,
            })}
          />
          <FormMessage name="firstDeposit" />
        </div>
        <div className="flex items-center gap-2.5 p-5 rounded-2xl border border-[#DDF2DB] bg-[#EBFDE9] text-[#536751] text-xs sm:text-sm">
          In case nobody else joins, you will be able to withdraw when the game expires.
          <br />
          It may take some time to process your deposit.
        </div>
        {formCtx.formState.errors.root && (
          <div className="flex items-center text-red-600 text-xs sm:text-sm">
            {formCtx.formState.errors.root.message}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <button
          className="btn w-[279px]"
          disabled={isInvalid || !isActive || isLoading}
          onClick={() => {
            handleNext().catch(console.warn);
          }}
        >
          {isLoading ? 'Loading...' : 'Launch game'}
        </button>
        <button className="flex items-center gap-4" onClick={handlePrev}>
          <span className="rotate-180">
            <Icons.ArrowRight color="black" />
          </span>
          Previous step
        </button>
      </div>
    </NewGameStep>
  );
}
